import dayjs from "dayjs";
import { Image, Text } from "@chakra-ui/react";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { PiCertificate, PiChalkboardTeacher, PiSparkle } from "react-icons/pi";
import { LuClock3 } from "react-icons/lu";
import UserCheck from "../../assets/icons/components/UserCheck";

dayjs.extend(advancedFormat);
export const courDataDynamic = (userType: string, newUser?: boolean) => {
  const baseData = {
    IIT_MANDI_BA: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-11-17T10:00:00",
      testDateTimeOver: "2024-11-17T22:00:00",
      resultDate: userType === "ranker" ? (newUser ? "2024-11-18T09:00:00" : "2024-10-28T17:00:00") : newUser ? "2024-10-30T10:00:00" : "2024-10-30T10:00:00",
      onboardingDate: userType === "ranker" ? (newUser ? "2024-11-03T23:59:00" : "2024-11-03T23:59:00") : newUser ? "2024-11-03T23:59:00" : "2024-11-23T23:59:00",
      courseOverSessionDate: userType === "ranker" ? (newUser ? "2024-10-28T19:00:00" : "2024-10-28T19:00:00") : newUser ? "2024-10-30T17:00:00" : "2024-10-30T17:00:00",
      mockEnablingTime: newUser ? "2024-11-14T11:00:00" : "2024-10-26T11:00:00",
      mockDisablingTime: newUser ? "2024-11-17T21:00:00" : "2024-10-27T21:00:00",
      // ?  ======== Configurable dates =======
      email: "info.cce@iitmandi.ac.in",
      yt: "https://youtu.be/O9P9sm0AK4s",
      zoom: "https://us06web.zoom.us/j/88504991222",
      userList: "https://masai-iit-for-all.s3.ap-south-1.amazonaws.com/brochures/CCE_IIT_Mandi_BA_Results_241028.pdf",
      seatBookingAmount: 4000,
      collage: "CCE, IIT Mandi",
      selectColor: "#52BD94",
      borderColor: "#FDC56A",
      backgroundColor: "#FFF9F0",
      courseFee: 56000,
      darkBackgroundColor: "#FEE8C8",
      coursePayment: 99,
      assesmentpageTitle: "Entrance Test for CCE, IIT Mandi's <br/> Minor in Business Analytics Program",
      title: "Minor in Business Analytics",
      collegeName: "CCE, IIT Mandi",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/course1_6549b2bb62.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "100% refund, if you take the test and don't enrol in the program"],
      },
      rankerListData: {
        borderColor: "#FCA311",
        background: "rgba(252, 163, 17, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88504991222",
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Programming", "Basic Maths", "Data Interpretation", "Logical Reasoning"],
      },
      feePaymentDeadline: "25th Nov",
      emiTenior: 8,
    },
    IIT_MANDI_ES: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-11-17T10:00:00",
      testDateTimeOver: "2024-11-17T22:00:00",
      resultDate: userType === "ranker" ? (newUser ? "2024-11-18T09:00:00" : "2024-10-28T17:00:00") : newUser ? "2024-10-30T10:00:00" : "2024-10-30T10:00:00",
      onboardingDate: userType === "ranker" ? (newUser ? "2024-11-03T23:59:00" : "2024-11-03T23:59:00") : newUser ? "2024-11-03T23:59:00" : "2024-11-23T23:59:00",
      courseOverSessionDate: userType === "ranker" ? (newUser ? "2024-10-28T19:00:00" : "2024-10-28T19:00:00") : newUser ? "2024-10-30T17:00:00" : "2024-10-30T17:00:00",
      mockEnablingTime: newUser ? "2024-11-14T11:00:00" : "2024-10-26T11:00:00",
      mockDisablingTime: newUser ? "2024-11-17T21:00:00" : "2024-10-27T21:00:00",
      // ?  ======== Configurable dates =======
      email: "info.cce@iitmandi.ac.in",
      zoom: "https://us06web.zoom.us/j/88039494513",
      yt: "https://youtu.be/mjKOI69w_Qc",
      userList: "https://masai-iit-for-all.s3.ap-south-1.amazonaws.com/brochures/CCE_IIT_Mandi_ES_Results_241028.pdf.pdf",
      seatBookingAmount: 4000,
      collage: "CCE, IIT Mandi",
      selectColor: "#005792",
      borderColor: "#BDF2FF",
      backgroundColor: "#F0FCFF",
      darkBackgroundColor: "#D6F7FF",
      courseFee: 60000,
      coursePayment: 99,
      title: "Minor in Embedded Systems",
      collegeName: "CCE, IIT Mandi",
      assesmentpageTitle: "Entrance Test for CCE, IIT Mandi's <br/> Minor in Embedded Systems",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/course3_df5e3542f3.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "100% refund, if you take the test and don't enrol in the program"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Programming", "Logical Circuits", "Logical Reasoning", "Quantitative Aptitude"],
      },
      rankerListData: {
        borderColor: "#005792",
        background: "rgba(163, 237, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88039494513",
      },
      feePaymentDeadline: "25th Nov",
      emiTenior: 8,
    },
    IIT_GUWAHATI_DS_ML: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-11-10T10:00:00",
      testDateTimeOver: "2024-11-10T22:00:00",
      resultDate: userType === "ranker" ? (newUser ? "2024-11-11T11:00:00" : "2024-10-28T17:00:00") : newUser ? "2024-11-13T09:00:00" : "2024-10-30T17:00:00",
      onboardingDate: userType === "ranker" ? (newUser ? "2024-11-28T23:59:00" : "2024-11-28T23:59:00") : newUser ? "2024-11-28T23:59:00" : "2024-11-28T23:59:00",
      courseOverSessionDate: userType === "ranker" ? (newUser ? "2024-11-11T19:00:00" : "") : newUser ? "2024-11-11T19:00:00" : "",
      mockEnablingTime: "2024-11-07T11:00:00",
      mockDisablingTime: "2024-11-10T21:00:00",
      // ?  ======== Configurable dates =======
      email: "iitg.daksh.gurukul@masaischool.com",
      zoom: "https://us06web.zoom.us/j/88034220522",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-iit-for-all.s3.ap-south-1.amazonaws.com/brochures/DakshGurukul_IITGuwahati_Results_241028.pdf",
      seatBookingAmount: 8000,
      collage: "Daksh Gurukul - IIT Guwahati",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#F7F7FF",
      darkBackgroundColor: "#DBDBFF",
      courseFee: 60000,
      coursePayment: 99,
      title: "Credit-Linked Program in Data Science",
      assesmentpageTitle: "Entrance Test for Daksh Gurukul, IIT Guwahati's <br/> Credit-Linked Program in Data Science",
      collegeName: "Daksh Gurukul, IIT Guwahati",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/course2_095b9c5095.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "100% refund, if you take the test and don't enrol in the program"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Programming", "Mathematics", "Statistics", "Communication Skills"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "25th Nov",
      emiTenior: 8,
    },
    IIT_ROPAR: {
      isCourseOverviewEnabled: true,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-11-10T10:00:00",
      testDateTimeOver: "2024-11-10T22:00:00",
      resultDate: userType === "ranker" ? (newUser ? "2024-11-11T09:00:00" : "2024-11-04T11:00:00") : newUser ? "2024-11-13T09:00:00" : "2024-11-06T11:00:00",
      onboardingDate: userType === "ranker" ? (newUser ? "2024-11-12T23:59:00" : "2024-11-08T23:59:00") : newUser ? "2024-11-15T23:59:00" : "2024-11-08T23:59:00",
      courseOverSessionDate: userType === "ranker" ? (newUser ? "2024-11-11T19:00:00" : "2024-11-04T17:30:00") : newUser ? "2024-11-11T19:00:00" : "2024-11-06T19:00:00",
      mockEnablingTime: newUser ? "2024-11-07T11:00:00" : "2024-11-05T11:00:00",
      mockDisablingTime: newUser ? "2024-11-10T21:00:00" : "2024-11-10T21:00:00",
      // ?  ======== Configurable dates =======
      email: "support_aiforall@iitrpr.ac.in",
      zoom: "https://us06web.zoom.us/j/82792154343",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Ropar",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#EBEEFE",
      darkBackgroundColor: "#B5BDFC",
      courseFee: 40000,
      coursePayment: 99,
      title: "Minor in Artificial Intelligence ( AI )",
      assesmentpageTitle: "Entrance Test for IIT Ropar's <br/> Minor in Artificial Intelligence ( AI )",
      collegeName: "IIT Ropar",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/image-1.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "100% refund, if you take the test and don't enrol in the program"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Logical Reasoning", "Aptitude", "Basic Math"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "25th Nov",
      emiTenior: 8,
    },
    IIT_MANDI_V2: {
      isCourseOverviewEnabled: true,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-11-17T17:00:00",
      testDateTimeOver: "2024-11-17T22:00:00",
      resultDate: userType === "ranker" ? "2024-11-18T09:00:00" : "2024-11-20T09:00:00",
      onboardingDate: userType === "ranker" ? "2024-11-08T23:59:00" : "2024-11-23T23:59:00",
      courseOverSessionDate: userType === "ranker" ? "2024-11-08T23:59:00" : "2024-11-08T23:59:00",
      mockEnablingTime: "2024-11-14T11:00:00",
      mockDisablingTime: "2024-11-18T21:00:00",
      // ?  ======== Configurable dates =======
      email: "info.cce@iitmandi.ac.in",
      zoom: "https://us06web.zoom.us/j/82792154343",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Mandi",
      selectColor: "#52BD94", // Updated to match IIT_MANDI
      borderColor: "#FDC56A", // Updated to match IIT_MANDI
      backgroundColor: "#FFF9F0", // Updated to match IIT_MANDI
      darkBackgroundColor: "#FEE8C8", // Updated to match IIT_MANDI
      courseFee: 40000,
      coursePayment: 99,
      title: "Minor in Computer Science by CCE, IIT Mandi",
      assesmentpageTitle: "Entrance Test for IIT Mandi's <br/> Minor in Computer Science",
      collegeName: "IIT Mandi",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/CSE.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "100% refund, if you take the test and don't enrol in the program"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Basic Programming", "CS Fundamentals", "Basic Maths"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "26th Nov",
      emiTenior: 8,
    },
    IIT_GUWAHATI_DS_ML_V2: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-11-24T10:00:00",
      testDateTimeOver: "2024-11-24T22:00:00",
      resultDate: userType === "ranker" ? "2024-11-25T17:00:00" : "2024-11-27T09:00:00",
      onboardingDate: userType === "ranker" ? "2024-11-28T22:00:00" : "2024-11-28T22:00:00",
      courseOverSessionDate: userType === "ranker" ? (newUser ? "2024-11-11T19:00:00" : "") : newUser ? "2024-11-11T19:00:00" : "",
      mockEnablingTime: "2024-11-19T11:00:00",
      mockDisablingTime: "2024-11-24T21:00:00",
      // ?  ======== Configurable dates =======
      email: "iitg.daksh.gurukul@masaischool.com",
      zoom: "https://us06web.zoom.us/j/88034220522",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-iit-for-all.s3.ap-south-1.amazonaws.com/brochures/DakshGurukul_IITGuwahati_Results_241028.pdf",
      seatBookingAmount: 4000,
      collage: "Daksh Gurukul - IIT Guwahati",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#F7F7FF",
      darkBackgroundColor: "#DBDBFF",
      courseFee: 60000,
      coursePayment: 99,
      title: "Credit-Linked Program in Data Science",
      assesmentpageTitle: "Entrance Test for Daksh Gurukul, IIT Guwahati's <br/> Credit-Linked Program in Data Science",
      collegeName: "Daksh Gurukul, IIT Guwahati",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/course2_095b9c5095.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Programming", "Mathematics", "Statistics"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "9th Dec",
      emiTenior: 8,
    },
    IIT_ROPAR_V2: {
      isCourseOverviewEnabled: true,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-11-24T10:00:00",
      testDateTimeOver: "2024-11-24T22:00:00",
      resultDate: userType === "ranker" ? "2024-11-25T17:00:00" : "2024-11-27T09:00:00",
      onboardingDate: userType === "ranker" ? "2024-11-28T22:00:00" : "2024-11-28T22:00:00",
      courseOverSessionDate: userType === "ranker" ? (newUser ? "2024-11-11T19:00:00" : "") : newUser ? "2024-11-11T19:00:00" : "",
      mockEnablingTime: "2024-11-19T11:00:00",
      mockDisablingTime: "2024-11-24T21:00:00",
      // ?  ======== Configurable dates =======
      email: "support_aiforall@iitrpr.ac.in",
      zoom: "https://us06web.zoom.us/j/82792154343",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Ropar",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#EBEEFE",
      darkBackgroundColor: "#B5BDFC",
      courseFee: 40000,
      coursePayment: 99,
      title: "Minor in Artificial Intelligence ( AI )",
      assesmentpageTitle: "Entrance Test for IIT Ropar's <br/> Minor in Artificial Intelligence ( AI )",
      collegeName: "IIT Ropar",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/image-1.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Logical Reasoning", "Aptitude", "Basic Math"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "9th Dec",
      emiTenior: 8,
    },
    IIT_ROPAR_V3: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-11-24T10:00:00",
      testDateTimeOver: "2024-11-24T22:00:00",
      resultDate: userType === "ranker" ? "2024-11-25T17:00:00" : "2024-11-27T09:00:00",
      onboardingDate: userType === "ranker" ? "2024-11-28T22:00:00" : "2024-11-28T22:00:00",
      courseOverSessionDate: userType === "ranker" ? (newUser ? "2024-11-11T19:00:00" : "") : newUser ? "2024-11-11T19:00:00" : "",
      mockEnablingTime: "2024-11-21T11:00:00",
      mockDisablingTime: "2024-11-24T21:00:00",
      // ?  ======== Configurable dates =======
      email: "support_aiforall@iitrpr.ac.in",
      zoom: "https://us06web.zoom.us/j/82792154343",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Ropar",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#EBEEFE",
      darkBackgroundColor: "#B5BDFC",
      courseFee: 40000,
      coursePayment: 99,
      title: "Minor in Artificial Intelligence ( AI )",
      assesmentpageTitle: "Entrance Test for IIT Ropar's <br/> Minor in Artificial Intelligence ( AI )",
      collegeName: "IIT Ropar",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/image-1.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "100% refund if you don't enroll in the course"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Aptitude", "Logical Reasoning"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "9th Dec",
      emiTenior: 8,
    },

    // Business Analytics
    IIT_MANDI_BA_V2: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-12-01T17:00:00",
      testDateTimeOver: "2024-12-01T22:00:00",
      resultDate: userType === "ranker" ? "2024-12-02T09:00:00" : "2024-12-04T09:00:00",
      onboardingDate: userType === "ranker" ? "2024-12-03T09:00:00" : "2024-12-05T09:00:00",
      courseOverSessionDate: userType === "ranker" ? "2024-11-08T23:59:00" : "2024-11-08T23:59:00",
      mockEnablingTime: "2024-11-28T11:00:00",
      mockDisablingTime: "2024-12-01T21:00:00",
      // ?  ======== Configurable dates =======
      email: "info.cce@iitmandi.ac.in",
      yt: "https://youtu.be/O9P9sm0AK4s",
      zoom: "https://us06web.zoom.us/j/88504991222",
      userList: "https://masai-iit-for-all.s3.ap-south-1.amazonaws.com/brochures/CCE_IIT_Mandi_BA_Results_241028.pdf",
      seatBookingAmount: 4000,
      collage: "CCE, IIT Mandi",
      selectColor: "#52BD94",
      borderColor: "#FDC56A",
      backgroundColor: "#FFF9F0",
      courseFee: 56000,
      darkBackgroundColor: "#FEE8C8",
      coursePayment: 99,
      assesmentpageTitle: "Entrance Test for CCE, IIT Mandi's <br/> Minor in Business Analytics Program",
      title: "Minor in Business Analytics",
      collegeName: "CCE, IIT Mandi",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/course1_6549b2bb62.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      rankerListData: {
        borderColor: "#FCA311",
        background: "rgba(252, 163, 17, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88504991222",
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Programming", "Basic Maths", "Data Interpretation", "Logical Reasoning"],
      },
      feePaymentDeadline: "9th Dec",
      emiTenior: 8,
    },
    // ?Course launch on 1st Dec
    // cse
    IIT_MANDI_V3: {
      isCourseOverviewEnabled: true,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-12-01T17:00:00",
      testDateTimeOver: "2024-12-01T22:00:00",
      resultDate: userType === "ranker" ? "2024-12-03T11:00:00" : "2024-12-05T11:00:00",
      onboardingDate: userType === "ranker" ? "2024-12-03T09:00:00" : "2024-12-05T09:00:00",
      courseOverSessionDate: userType === "ranker" ? "2024-11-08T23:59:00" : "2024-11-08T23:59:00",
      mockEnablingTime: "2024-11-28T11:00:00",
      mockDisablingTime: "2024-12-01T21:00:00",
      // ?  ======== Configurable dates =======
      email: "info.cce@iitmandi.ac.in",
      zoom: "https://us06web.zoom.us/j/82792154343",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Mandi",
      selectColor: "#52BD94", // Updated to match IIT_MANDI
      borderColor: "#FDC56A", // Updated to match IIT_MANDI
      backgroundColor: "#FFF9F0", // Updated to match IIT_MANDI
      darkBackgroundColor: "#FEE8C8", // Updated to match IIT_MANDI
      courseFee: 60000,
      coursePayment: 99,
      title: "Minor in Computer Science by CCE, IIT Mandi",
      assesmentpageTitle: "Entrance Test for IIT Mandi's <br/> Minor in Computer Science",
      collegeName: "IIT Mandi",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/CSE.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Basic Programming", "CS Fundamentals", "Basic Maths"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "16th Dec",
      emiTenior: 8,
    },
    // ai for management
    IIT_ROPAR_V4: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-12-01T17:00:00",
      testDateTimeOver: "2024-12-01T22:00:00",
      resultDate: userType === "ranker" ? "2024-12-02T11:00:00" : "2024-12-05T11:00:00",
      onboardingDate: userType === "ranker" ? "2024-12-02T09:00:00" : "2024-12-05T09:00:00",
      courseOverSessionDate: userType === "ranker" ? "2024-11-08T23:59:00" : "2024-11-08T23:59:00",
      mockEnablingTime: "2024-11-28T11:00:00",
      mockDisablingTime: "2024-12-01T21:00:00",
      // ?  ======== Configurable dates =======
      email: "support_aiforall@iitrpr.ac.in",
      zoom: "https://us06web.zoom.us/j/82792154343",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Ropar",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#EBEEFE",
      darkBackgroundColor: "#B5BDFC",
      courseFee: 60000,
      coursePayment: 99,
      title: "Minor in Artificial Intelligence ( AI )",
      assesmentpageTitle: "Entrance Test for IIT Ropar's <br/> Minor in Artificial Intelligence ( AI )",
      collegeName: "IIT Ropar",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/image-1.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "100% refund if you don't enroll in the course"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Logical Reasoning", "Aptitude", "Basic Math"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "16th Dec",
      emiTenior: 8,
    },
    // cyber security
    IIT_ROPAR_CYBER_SECURITY: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-12-01T17:00:00",
      testDateTimeOver: "2024-12-01T22:00:00",
      resultDate: userType === "ranker" ? "2024-12-03T11:00:00" : "2024-12-04T11:00:00",
      onboardingDate: userType === "ranker" ? "2024-12-03T09:00:00" : "2024-12-05T09:00:00",
      courseOverSessionDate: userType === "ranker" ? "2024-11-08T23:59:00" : "2024-11-08T23:59:00",
      mockEnablingTime: "2024-11-28T11:00:00",
      mockDisablingTime: "2024-12-01T21:00:00",
      // ?  ======== Configurable dates =======
      email: "support_aiforall@iitrpr.ac.in",
      zoom: "https://us06web.zoom.us/j/82792154343",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Ropar",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#EBEEFE",
      darkBackgroundColor: "#B5BDFC",
      courseFee: 60000,
      coursePayment: 99,
      title: "Minor in Cyber Security & Ethical Hacking",
      assesmentpageTitle: "Entrance Test for IIT Ropar's <br/> Minor in Cyber Security & Ethical Hacking",
      collegeName: "IIT Ropar",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/image-1.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/ShieldCheck.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "100% refund if you don't enroll in the course"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Basic of Programming", "Basics of Networking", "Aptitude"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "16th Dec",
      emiTenior: 8,
    },

    //
    IIT_GUWAHATI_CSE: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-12-01T17:00:00",
      testDateTimeOver: "2024-12-01T22:00:00",
      resultDate: userType === "ranker" ? "2024-12-04T11:00:00" : "2024-12-06T11:00:00",
      onboardingDate: userType === "ranker" ? "2024-12-03T09:00:00" : "2024-12-05T09:00:00",
      courseOverSessionDate: userType === "ranker" ? "2024-11-08T23:59:00" : "2024-11-08T23:59:00",
      mockEnablingTime: "2024-11-28T11:00:00",
      mockDisablingTime: "2024-12-01T21:00:00",
      // ?  ======== Configurable dates =======
      email: "admissions.daksh.gurukul.iitg@masaischool.com",
      zoom: "https://us06web.zoom.us/j/88034220522",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-iit-for-all.s3.ap-south-1.amazonaws.com/brochures/DakshGurukul_IITGuwahati_Results_241028.pdf",
      seatBookingAmount: 8000,
      collage: "Daksh Gurukul - IIT Guwahati",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#EAF5FB",
      darkBackgroundColor: "#9ED3EB",
      courseFee: 140000,
      coursePayment: 99,
      title: "Credit-Linked program in CSE & AI",
      assesmentpageTitle: "Entrance Test for Daksh Gurukul, IIT Guwahati's <br/> Credit-Linked program in CSE with AI",
      collegeName: "Daksh Gurukul, IIT Guwahati",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/DS.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Basic Programming", "CS Fundamentals", "Basic Mathematics"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "16th Dec",
      emiTenior: 12,
    },
    // ! ======== 8th Dec code ========
    IIT_MANDI_BA_8_DEC_24: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-12-08T10:00:00",
      testDateTimeOver: "2024-12-08T22:00:00",
      resultDate: userType === "ranker" ? "2024-12-11T11:00:00" : "2024-12-13T11:00:00",
      onboardingDate: userType === "ranker" ? "2024-12-12T23:59:00" : "2024-12-14T23:59:00",
      courseOverSessionDate: userType === "ranker" ? "2024-12-05T19:00:00" : "2024-12-05T17:00:00",
      mockEnablingTime: "2024-12-05T11:00:00",
      mockDisablingTime: "2024-12-08T21:00:00",
      // ?  ======== Configurable dates =======
      email: "admissions.cce.iitmd@masaischool.com",
      yt: "https://youtu.be/O9P9sm0AK4s",
      zoom: "https://us06web.zoom.us/j/88504991222",
      userList: "https://masai-iit-for-all.s3.ap-south-1.amazonaws.com/brochures/CCE_IIT_Mandi_BA_Results_241028.pdf",
      seatBookingAmount: 4000,
      collage: "CCE, IIT Mandi",
      selectColor: "#52BD94",
      borderColor: "#FDC56A",
      backgroundColor: "#FFF9F0",
      courseFee: 60000,
      darkBackgroundColor: "#FEE8C8",
      coursePayment: 99,
      assesmentpageTitle: "Entrance Test for CCE, IIT Mandi's <br/> Minor in Business Analytics Program",
      title: "Minor in Business Analytics",
      collegeName: "CCE, IIT Mandi",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/course1_6549b2bb62.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "100% refund, if you take the test and don't enrol in the program"],
      },
      rankerListData: {
        borderColor: "#FCA311",
        background: "rgba(252, 163, 17, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88504991222",
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Programming", "Basic Maths", "Data Interpretation", "Logical Reasoning"],
      },
      feePaymentDeadline: "23rd Dec",
      emiTenior: 8,
    },
    IIT_GUWAHATI_DS_8_DEC_24: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-12-08T10:00:00",
      testDateTimeOver: "2024-12-08T22:00:00",
      resultDate: userType === "ranker" ? "2024-12-09T11:00:00" : "2024-12-11T11:00:00",
      onboardingDate: userType === "ranker" ? "2024-12-10T23:59:00" : "2024-12-12T23:59:00",
      courseOverSessionDate: userType === "ranker" ? "2024-12-05T19:00:00" : "2024-12-05T17:00:00",
      mockEnablingTime: "2024-12-05T11:00:00",
      mockDisablingTime: "2024-12-08T21:00:00",
      // ?  ======== Configurable dates =======
      email: "admissions.daksh.gurukul.iitg@masaischool.com",
      zoom: "https://us06web.zoom.us/j/88034220522",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-iit-for-all.s3.ap-south-1.amazonaws.com/brochures/DakshGurukul_IITGuwahati_Results_241028.pdf",
      seatBookingAmount: 4000,
      collage: "Daksh Gurukul - IIT Guwahati",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#F7F7FF",
      darkBackgroundColor: "#DBDBFF",
      courseFee: 60000,
      coursePayment: 99,
      title: "Credit-Linked Program in Data Science",
      assesmentpageTitle: "Qualifier Test for Daksh Gurukul, IIT Guwahati's <br/> Credit-Linked Program in Data Science",
      collegeName: "Daksh Gurukul, IIT Guwahati",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/course2_095b9c5095.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Statistics", "Arithmetic Aptitude", "Logical Reasoning", "Basics of Programming"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "19th Dec",
      emiTenior: 8,
    },
    IIT_MANDI_ES_8_DEC_24: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-12-08T10:00:00",
      testDateTimeOver: "2024-12-08T22:00:00",
      resultDate: userType === "ranker" ? "2024-12-11T11:00:00" : "2024-12-13T11:00:00",
      onboardingDate: userType === "ranker" ? "2024-12-07T23:59:00" : "2024-12-11T23:59:00",
      courseOverSessionDate: userType === "ranker" ? "2024-12-05T19:00:00" : "2024-12-05T17:00:00",
      mockEnablingTime: "2024-12-05T11:00:00",
      mockDisablingTime: "2024-12-08T21:00:00",
      // ?  ======== Configurable dates =======
      email: "admissions.cce.iitmd@masaischool.com",
      zoom: "https://us06web.zoom.us/j/88039494513",
      yt: "https://youtu.be/mjKOI69w_Qc",
      userList: "https://masai-iit-for-all.s3.ap-south-1.amazonaws.com/brochures/CCE_IIT_Mandi_ES_Results_241028.pdf.pdf",
      seatBookingAmount: 4000,
      collage: "CCE, IIT Mandi",
      selectColor: "#005792",
      borderColor: "#BDF2FF",
      backgroundColor: "#F0FCFF",
      darkBackgroundColor: "#D6F7FF",
      courseFee: 70000,
      coursePayment: 99,
      title: "Minor in Embedded Systems",
      collegeName: "CCE, IIT Mandi",
      assesmentpageTitle: "Qualifier Test for CCE, IIT Mandi's <br/> Minor in Embedded Systems",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/course3_df5e3542f3.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "100% refund, if you take the test and don't enrol in the program"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Basic Programming", "Logical Circuits", "Logical Reasoning", "Quantitative Aptitude"],
      },
      rankerListData: {
        borderColor: "#005792",
        background: "rgba(163, 237, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88039494513",
      },
      feePaymentDeadline: "19th Dec",
      emiTenior: 12,
    },
    IIT_MANG_COMPETITIVE_PROGRAMMING_8_DEC_24: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-12-08T10:00:00",
      testDateTimeOver: "2024-12-08T22:00:00",
      resultDate: userType === "ranker" ? "2024-12-11T11:00:00" : "2024-12-13T11:00:00",
      onboardingDate: userType === "ranker" ? "2024-12-12T23:59:00" : "2024-12-12T23:59:00",
      courseOverSessionDate: userType === "ranker" ? "2024-12-05T19:00:00" : "2024-12-05T17:00:00",
      mockEnablingTime: "2024-12-05T11:00:00",
      mockDisablingTime: "2024-12-08T21:00:00",
      // ?  ======== Configurable dates =======
      email: "topup@masaischool.com",
      zoom: "https://us06web.zoom.us/j/88039494513",
      yt: "https://youtu.be/mjKOI69w_Qc",
      userList: "https://masai-iit-for-all.s3.ap-south-1.amazonaws.com/brochures/CCE_IIT_Mandi_ES_Results_241028.pdf.pdf",
      seatBookingAmount: 4000,
      collage: "",
      selectColor: "#52BD94",
      borderColor: "#BDF2FF",
      backgroundColor: "#EEFFF7",
      darkBackgroundColor: "#CFEEDF",
      courseFee: 30000,
      coursePayment: 99,
      title: "Competitive Programming Course",
      collegeName: "",
      assesmentpageTitle: "Qualifier Test for Competitive Programming Course",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Group.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/FileCode.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "100% refund, if you take the test and don't enrol in the program"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Programming", "Logical Reasoning", "Basic Maths"],
      },
      rankerListData: {
        borderColor: "#005792",
        background: "rgba(163, 237, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88039494513",
      },
      feePaymentDeadline: "29th Nov",
      emiTenior: 4,
    },

    // ! ======== 15th Dec code ========
    IIT_GUWAHATI_DEV_OPS_15_DEC_24: {
      isCourseOverviewEnabled: true,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-12-15T17:00:00",
      testDateTimeOver: "2024-12-15T22:00:00",
      resultDate: "2024-12-20T11:00:00",
      onboardingDate: userType === "ranker" ? "2024-12-22T23:59:00" : "2024-12-22T23:59:00",
      courseOverSessionDate: "2024-12-20T20:00:00",
      mockEnablingTime: "2024-12-10T11:00:00",
      mockDisablingTime: "2024-12-15T21:00:00",
      // ?  ======== Configurable dates =======
      email: "admissions.daksh.gurukul.iitg@masaischool.com",
      zoom: "https://us06web.zoom.us/j/86714976781",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Guwahati",
      selectColor: "#7962DF", // Updated to match IIT_MANDI
      borderColor: "#0E4997", // Updated to match IIT_MANDI
      backgroundColor: "#F8F6FF", // Updated to match IIT_MANDI
      darkBackgroundColor: "#E3DDFA", // Updated to match IIT_MANDI
      courseFee: 100000,
      coursePayment: 99,
      title: "Minor in DevOps",
      assesmentpageTitle: "Entrance Test for IIT Guwahati's <br/> Minor in DevOps",
      collegeName: "IIT Guwahati",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Infinity_1c9a6ab3b2.jpg",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "100% refund, if you take the test and don't enrol in the program"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Basic Programming", "Mathematics", "Arithmetic Aptitude", "Problem Solving Ability"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "30th Dec",
      emiTenior: 6,
    },

    IIT_MANG_ADVANCE_BACKEND_15_DEC_24: {
      isCourseOverviewEnabled: true,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-12-15T17:00:00",
      testDateTimeOver: "2024-12-15T22:00:00",
      resultDate: "2024-12-20T11:00:00",
      onboardingDate: userType === "ranker" ? "2024-12-22T23:59:00" : "2024-12-22T23:59:00",

      courseOverSessionDate: "2024-12-20T20:00:00",

      mockEnablingTime: "2024-12-10T11:00:00",
      mockDisablingTime: "2024-12-15T21:00:00",
      // ?  ======== Configurable dates =======
      email: "topup@masaischool.com",
      zoom: "https://us06web.zoom.us/j/81110443663",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "Industry Experts",
      selectColor: "#34885B", // Updated to match IIT_MANDI
      borderColor: "#0E4997", // Updated to match IIT_MANDI
      backgroundColor: "#EEFFF7", // Updated to match IIT_MANDI
      darkBackgroundColor: "#CFEEDF", // Updated to match IIT_MANDI
      courseFee: 30000,
      coursePayment: 99,
      title: "Backend Engineering Course",
      assesmentpageTitle: "Entrance Test for <br/> Backend Engineering Course",
      collegeName: "",
      logo: "https://cdn.masaischool.com/masai-website/Masai_Logo_dark_web_b21aab8c62.webp",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Code_Block_3354ffba31.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "100% refund, if you take the test and don't enrol in the program"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Basic Programming", "Mathematics", "Arithmetic Aptitude", "Problem Solving Ability"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "30 Dec",
      emiTenior: 4,
    },

    IIT_ROPAR_AI_CSE_15_DEC_24: {
      isCourseOverviewEnabled: true,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-12-15T10:00:00",
      testDateTimeOver: "2024-12-15T22:00:00",
      resultDate: "2024-12-16T11:00:00",
      onboardingDate: userType === "ranker" ? "2024-12-22T23:59:00" : "2024-12-22T23:59:00",
      courseOverSessionDate: userType === "ranker" ? (newUser ? "2024-11-11T19:00:00" : "") : newUser ? "2024-11-11T19:00:00" : "",
      mockEnablingTime: "2024-12-11T11:00:00",
      mockDisablingTime: "2024-12-15T21:00:00",
      // ?  ======== Configurable dates =======
      email: "support_aiforall@iitrpr.ac.in",
      zoom: "https://us06web.zoom.us/j/82792154343",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Ropar",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#EBEEFE",
      darkBackgroundColor: "#B5BDFC",
      courseFee: 60000,
      coursePayment: 99,
      title: "Minor in Artificial Intelligence ( AI )",
      assesmentpageTitle: "Entrance Test for IIT Ropar's <br/> Minor in Artificial Intelligence ( AI )",
      collegeName: "IIT Ropar",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/image-1.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Logical Reasoning", "Aptitude", "Basic Math"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "30th Dec",
      emiTenior: 8,
    },
    IIT_ROPAR_AI_NONTECH_15_DEC_24: {
      isCourseOverviewEnabled: true,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-12-15T10:00:00",

      testDateTimeOver: "2024-12-15T22:00:00",
      resultDate: "2024-12-19T11:00:00",
      onboardingDate: userType === "ranker" ? "2024-12-22T23:59:00" : "2024-12-22T23:59:00",
      courseOverSessionDate: "2024-11-11T19:00:00",
      mockEnablingTime: "2024-12-11T11:00:00",
      mockDisablingTime: "2024-12-15T21:00:00",
      // ?  ======== Configurable dates =======
      email: "support_aiforall@iitrpr.ac.in",
      zoom: "https://us06web.zoom.us/j/82792154343",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Ropar",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#EBEEFE",
      darkBackgroundColor: "#B5BDFC",
      courseFee: 60000,
      coursePayment: 99,
      title: "Minor in Artificial Intelligence ( AI )",
      assesmentpageTitle: "Entrance Test for IIT Ropar's <br/> Minor in Artificial Intelligence ( AI )",
      collegeName: "IIT Ropar",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/image-1.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Logical Reasoning", "Aptitude", "Basic Math"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "30th Dec",
      emiTenior: 8,
    },

    // ! Manually created dummy course
    IIT_MANDI_DUMMY_TEST_12_DEC: {
      isCourseOverviewEnabled: true,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-12-01T17:00:00",
      testDateTimeOver: "2024-12-01T22:00:00",
      resultDate: "2024-12-03T11:00:00",
      onboardingDate: userType === "ranker" ? "2024-12-03T09:00:00" : "2024-12-05T09:00:00",
      courseOverSessionDate: userType === "ranker" ? "2024-11-08T23:59:00" : "2024-11-08T23:59:00",
      mockEnablingTime: "2024-11-28T11:00:00",
      mockDisablingTime: "2024-12-01T21:00:00",
      // ?  ======== Configurable dates =======
      email: "info.cce@iitmandi.ac.in",
      zoom: "https://us06web.zoom.us/j/82792154343",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Mandi",
      selectColor: "#52BD94", // Updated to match IIT_MANDI
      borderColor: "#FDC56A", // Updated to match IIT_MANDI
      backgroundColor: "#FFF9F0", // Updated to match IIT_MANDI
      darkBackgroundColor: "#FEE8C8", // Updated to match IIT_MANDI
      courseFee: 60000,
      coursePayment: 99,
      title: "Minor in Computer Science by CCE, IIT Mandi",
      assesmentpageTitle: "Entrance Test for IIT Mandi's <br/> Minor in Computer Science",
      collegeName: "IIT Mandi",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/CSE.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Basic Programming", "CS Fundamentals", "Basic Maths"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "16th Dec",
      emiTenior: 8,
    },

    // ! ======== 22nd Dec code ========
    IIT_GUWAHATI_DS_ML_22_DEC_24: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-12-22T10:00:00",
      testDateTimeOver: "2024-12-22T22:00:00",
      resultDate: "2024-12-23T11:00:00",
      onboardingDate: userType === "ranker" ? "2024-12-25T23:59:00" : "2024-12-27T23:59:00",
      courseOverSessionDate: userType === "ranker" ? "2024-12-05T19:00:00" : "2024-12-05T17:00:00",
      mockEnablingTime: "2024-12-16T11:00:00",
      mockDisablingTime: "2024-12-22T21:00:00",
      // ?  ======== Configurable dates =======
      email: "admissions.daksh.gurukul.iitg@masaischool.com",
      zoom: "https://us06web.zoom.us/j/88034220522",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-iit-for-all.s3.ap-south-1.amazonaws.com/brochures/DakshGurukul_IITGuwahati_Results_241028.pdf",
      seatBookingAmount: 4000,
      collage: "Daksh Gurukul - IIT Guwahati",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#F7F7FF",
      darkBackgroundColor: "#DBDBFF",
      courseFee: 60000,
      coursePayment: 99,
      title: "Credit-Linked Program in Data Science",
      assesmentpageTitle: "Qualifier Test for Daksh Gurukul, IIT Guwahati's <br/> Credit-Linked Program in Data Science",
      collegeName: "Daksh Gurukul, IIT Guwahati",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/course2_095b9c5095.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Statistics", "Arithmetic Aptitude", "Logical Reasoning", "Basics of Programming"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "6th Jan 2025",
      emiTenior: 8,
    },

    // ! ======== 22nd Dec code ========

    IIT_MANDI_CSE_29_DEC_24: {
      isCourseOverviewEnabled: true,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-12-29T17:00:00",
      testDateTimeOver: "2024-12-29T22:00:00",
      resultDate:  "2024-12-30T11:00:00",
      onboardingDate: userType === "ranker" ? "2024-12-03T09:00:00" : "2025-12-05T09:00:00",
      courseOverSessionDate: userType === "ranker" ? "2024-01-25T23:59:00" : "2025-01-25T23:59:00",
      mockEnablingTime: "2024-12-25T11:00:00",
      mockDisablingTime: "2024-12-29T21:00:00",
      // ?  ======== Configurable dates =======
      email: "info.cce@iitmandi.ac.in",
      zoom: "https://us06web.zoom.us/j/82792154343",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Mandi",
      selectColor: "#52BD94", // Updated to match IIT_MANDI
      borderColor: "#FDC56A", // Updated to match IIT_MANDI
      backgroundColor: "#FFF9F0", // Updated to match IIT_MANDI
      darkBackgroundColor: "#FEE8C8", // Updated to match IIT_MANDI
      courseFee: 60000,
      coursePayment: 99,
      title: "Minor in Computer Science by CCE, IIT Mandi",
      assesmentpageTitle: "Entrance Test for IIT Mandi's <br/> Minor in Computer Science",
      collegeName: "IIT Mandi",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/CSE.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Basic Programming", "CS Fundamentals", "Basic Maths"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "13th Jan",
      emiTenior: 8,
    },

    // ! ======== 5th Jan code ========

    IIT_ROPAR_AI_CSE_5_JAN_25: {
      isCourseOverviewEnabled: true,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2025-01-05T10:00:00",
      testDateTimeOver: "2025-01-05T22:00:00",
      resultDate: "2025-01-06T11:00:00",
      onboardingDate:"2025-01-08T23:59:00",
      courseOverSessionDate: userType === "ranker" ? (newUser ? "2024-11-11T19:00:00" : "") : newUser ? "2024-11-11T19:00:00" : "",
      mockEnablingTime: "2025-01-01T11:00:00",
      mockDisablingTime: "2025-01-05T21:00:00",
      // ?  ======== Configurable dates =======
      email: "support_aiforall@iitrpr.ac.in",
      zoom: "https://us06web.zoom.us/j/82792154343",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Ropar",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#EBEEFE",
      darkBackgroundColor: "#B5BDFC",
      courseFee: 60000,
      coursePayment: 99,
      title: "Minor in Artificial Intelligence ( AI )",
      assesmentpageTitle: "Entrance Test for IIT Ropar's <br/> Minor in Artificial Intelligence ( AI )",
      collegeName: "IIT Ropar",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/image-1.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Logical Reasoning", "Aptitude", "Basic Math"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "20th Jan",
      emiTenior: 8,
    },
    IIT_ROPAR_AI_MANAGEMENT_5_JAN_25: {
      isCourseOverviewEnabled: true,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2025-01-05T10:00:00",
      testDateTimeOver: "2025-01-05T22:00:00",
      resultDate: "2025-01-06T11:00:00",
      onboardingDate:"2025-01-08T23:59:00",
      courseOverSessionDate: userType === "ranker" ? (newUser ? "2024-11-11T19:00:00" : "") : newUser ? "2024-11-11T19:00:00" : "",
      mockEnablingTime: "2025-01-01T11:00:00",
      mockDisablingTime: "2025-01-05T21:00:00",
      // ?  ======== Configurable dates =======
      email: "support_aiforall@iitrpr.ac.in",
      zoom: "https://us06web.zoom.us/j/82792154343",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Ropar",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#EBEEFE",
      darkBackgroundColor: "#B5BDFC",
      courseFee: 60000,
      coursePayment: 99,
      title: "Minor in Artificial Intelligence ( AI )",
      assesmentpageTitle: "Entrance Test for IIT Ropar's <br/> Minor in Artificial Intelligence ( AI )",
      collegeName: "IIT Ropar",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/image-1.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Logical Reasoning", "Aptitude", "Basic Math"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "20th Jan",
      emiTenior: 8,
    },
    IIT_MANDI_CSE_05_JAN_25: {
      isCourseOverviewEnabled: true,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2025-01-05T17:00:00",
      testDateTimeOver: "2025-01-05T22:00:00",
      resultDate:  "2025-01-06T11:00:00",
      onboardingDate: "2025-01-13T11:00:00",
      courseOverSessionDate: userType === "ranker" ? "2024-01-25T23:59:00" : "2025-01-25T23:59:00",
      mockEnablingTime: "2025-01-01T11:00:00",
      mockDisablingTime: "2025-01-12T21:00:00",
      // ?  ======== Configurable dates =======
      email: "info.cce@iitmandi.ac.in",
      zoom: "https://us06web.zoom.us/j/82792154343",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Mandi",
      selectColor: "#52BD94", // Updated to match IIT_MANDI
      borderColor: "#FDC56A", // Updated to match IIT_MANDI
      backgroundColor: "#FFF9F0", // Updated to match IIT_MANDI
      darkBackgroundColor: "#FEE8C8", // Updated to match IIT_MANDI
      courseFee: 60000,
      coursePayment: 99,
      title: "Minor in Computer Science by CCE, IIT Mandi",
      assesmentpageTitle: "Entrance Test for IIT Mandi's <br/> Minor in Computer Science",
      collegeName: "IIT Mandi",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/CSE.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Basic Programming", "CS Fundamentals", "Basic Maths"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "20th Jan",
      emiTenior: 8,
    },
    // ! ======== 12nd Jan code ========
    IIT_ROPAR_AI_CSE_12_JAN_25: {
      isCourseOverviewEnabled: true,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["14:00", "15:00"],
      },
      testDate: "2025-01-12T10:00:00",
      testDateTimeOver: "2025-01-12T22:00:00",
      resultDate: "2025-01-18T10:00:00",
      onboardingDate:"2025-01-15T23:59:00",
      courseOverSessionDate: userType === "ranker" ? (newUser ? "2024-11-11T19:00:00" : "") : newUser ? "2024-11-11T19:00:00" : "",
      mockEnablingTime: "2025-01-06T11:00:00",
      mockDisablingTime: "2025-01-12T21:00:00",
      // ?  ======== Configurable dates =======
      email: "support_aiforall@iitrpr.ac.in",
      zoom: "https://us06web.zoom.us/j/82792154343",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Ropar",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#EBEEFE",
      darkBackgroundColor: "#B5BDFC",
      courseFee: 60000,
      coursePayment: 99,
      title: "Minor in Artificial Intelligence ( AI )",
      assesmentpageTitle: "Entrance Test for IIT Ropar's <br/> Minor in Artificial Intelligence ( AI )",
      collegeName: "IIT Ropar",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/image-1.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Programming", "Mathematics", "Statistics"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "27th Jan",
      emiTenior: 8,
    },
    IIT_ROPAR_AI_NONTECH_12_JAN_25: {
      isCourseOverviewEnabled: true,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["14:00", "15:00"],
      },
      testDate: "2025-01-12T10:00:00",
      testDateTimeOver: "2025-01-12T22:00:00",
      resultDate: "2025-01-18T10:00:00",
      onboardingDate:"2035-01-15T23:59:00",
      courseOverSessionDate: userType === "ranker" ? (newUser ? "2024-11-11T19:00:00" : "") : newUser ? "2024-11-11T19:00:00" : "",
      mockEnablingTime: "2025-01-06T11:00:00",
      mockDisablingTime: "2025-01-12T21:00:00",
      // ?  ======== Configurable dates =======
      email: "support_aiforall@iitrpr.ac.in",
      zoom: "https://us06web.zoom.us/j/82792154343",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Ropar",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#EBEEFE",
      darkBackgroundColor: "#B5BDFC",
      courseFee: 60000,
      coursePayment: 99,
      title: "Minor in Artificial Intelligence ( AI )",
      assesmentpageTitle: "Entrance Test for IIT Ropar's <br/> Minor in Artificial Intelligence ( AI )",
      collegeName: "IIT Ropar",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/image-1.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Aptitude", "Logical Reasoning"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "27th Jan",
      emiTenior: 8,
    },
    IIT_GUWAHATI_DS_ML_12_JAN_25: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["14:00", "15:00"],
      },
      testDate: "2025-01-12T10:00:00",
      testDateTimeOver: "2025-01-12T22:00:00",
      resultDate: "2025-01-13T10:00:00",
      onboardingDate: "2025-01-15T23:59:00",
      courseOverSessionDate: userType === "ranker" ? "2024-12-05T19:00:00" : "2024-12-05T17:00:00",
      mockEnablingTime: "2025-01-06T11:00:00",
      mockDisablingTime: "2025-01-12T21:00:00",
      // ?  ======== Configurable dates =======
      email: "admissions.daksh.gurukul.iitg@masaischool.com",
      zoom: "https://us06web.zoom.us/j/88034220522",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-iit-for-all.s3.ap-south-1.amazonaws.com/brochures/DakshGurukul_IITGuwahati_Results_241028.pdf",
      seatBookingAmount: 4000,
      collage: "Daksh Gurukul - IIT Guwahati",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#F7F7FF",
      darkBackgroundColor: "#DBDBFF",
      courseFee: 60000,
      coursePayment: 99,
      title: "Credit-Linked Program in Data Science",
      assesmentpageTitle: "Qualifier Test for Daksh Gurukul, IIT Guwahati's <br/> Credit-Linked Program in Data Science",
      collegeName: "Daksh Gurukul, IIT Guwahati",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/course2_095b9c5095.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Programming", "Mathematics", "Statistics", "Communication Skills"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "27th Jan",
      emiTenior: 8,
    },
    IIT_MANDI_BA_12_JAN_25: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2025-01-12T10:00:00",
      testDateTimeOver: "2025-01-12T22:00:00",
      resultDate:  "2025-01-18T10:00:00",
      onboardingDate:  "2025-01-15T23:59:00",
      courseOverSessionDate: userType === "ranker" ? "2024-12-05T19:00:00" : "2024-12-05T17:00:00",
      mockEnablingTime: "2025-01-06T11:00:00",
      mockDisablingTime: "2025-01-12T21:00:00",
      // ?  ======== Configurable dates =======
      email: "admissions.cce.iitmd@masaischool.com",
      yt: "https://youtu.be/O9P9sm0AK4s",
      zoom: "https://us06web.zoom.us/j/88504991222",
      userList: "https://masai-iit-for-all.s3.ap-south-1.amazonaws.com/brochures/CCE_IIT_Mandi_BA_Results_241028.pdf",
      seatBookingAmount: 4000,
      collage: "CCE, IIT Mandi",
      selectColor: "#52BD94",
      borderColor: "#FDC56A",
      backgroundColor: "#FFF9F0",
      courseFee: 60000,
      darkBackgroundColor: "#FEE8C8",
      coursePayment: 99,
      assesmentpageTitle: "Entrance Test for CCE, IIT Mandi's <br/> Minor in Business Analytics Program",
      title: "Minor in Business Analytics",
      collegeName: "CCE, IIT Mandi",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/course1_6549b2bb62.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "100% refund, if you take the test and don't enrol in the program"],
      },
      rankerListData: {
        borderColor: "#FCA311",
        background: "rgba(252, 163, 17, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88504991222",
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Programming", "Basic Maths", "Data Interpretation", "Logical Reasoning"],
      },
      feePaymentDeadline: "27th Jan",
      emiTenior: 8,
    },
    // ! ======== 19th Jan code ========
    IIT_ROPAR_AI_CSE_19_JAN_25: {
      isCourseOverviewEnabled: true,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["14:00", "15:00"],
      },
      testDate: "2025-01-19T10:00:00",
      testDateTimeOver: "2025-01-19T22:00:00",
      resultDate: "2025-01-21T11:00:00",
      onboardingDate:"2025-01-15T23:59:00",
      courseOverSessionDate: userType === "ranker" ? (newUser ? "2024-11-11T19:00:00" : "") : newUser ? "2024-11-11T19:00:00" : "",
      mockEnablingTime: "2025-01-13T11:00:00",
      mockDisablingTime: "2025-01-19T21:00:00",
      // ?  ======== Configurable dates =======
      email: "support_aiforall@iitrpr.ac.in",
      zoom: "https://us06web.zoom.us/j/82792154343",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Ropar",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#EBEEFE",
      darkBackgroundColor: "#B5BDFC",
      courseFee: 60000,
      coursePayment: 99,
      title: "Minor in Artificial Intelligence ( AI )",
      assesmentpageTitle: "Entrance Test for IIT Ropar's <br/> Minor in Artificial Intelligence ( AI )",
      collegeName: "IIT Ropar",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/image-1.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Programming", "Mathematics", "Statistics"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "3rd Feb",
      emiTenior: 8,
    },
    IIT_ROPAR_AI_NONTECH_19_JAN_25: {
      isCourseOverviewEnabled: true,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["14:00", "15:00"],
      },
      testDate: "2025-01-19T10:00:00",
      testDateTimeOver: "2025-01-19T22:00:00",
      resultDate: "2025-01-21T11:00:00",
      onboardingDate:"2025-01-15T23:59:00",
      courseOverSessionDate: userType === "ranker" ? (newUser ? "2024-11-11T19:00:00" : "") : newUser ? "2024-11-11T19:00:00" : "",
      mockEnablingTime: "2025-01-13T11:00:00",
      mockDisablingTime: "2025-01-19T21:00:00",
      // ?  ======== Configurable dates =======
      email: "support_aiforall@iitrpr.ac.in",
      zoom: "https://us06web.zoom.us/j/82792154343",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Ropar",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#EBEEFE",
      darkBackgroundColor: "#B5BDFC",
      courseFee: 60000,
      coursePayment: 99,
      title: "Minor in Artificial Intelligence ( AI )",
      assesmentpageTitle: "Entrance Test for IIT Ropar's <br/> Minor in Artificial Intelligence ( AI )",
      collegeName: "IIT Ropar",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/image-1.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Aptitude", "Logical Reasoning"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "3rd Feb",
      emiTenior: 8,
    },
    IIT_GUWAHATI_DS_ML_19_JAN_25: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["14:00", "15:00"],
      },
      testDate: "2025-01-19T10:00:00",
      testDateTimeOver: "2025-01-19T22:00:00",
      resultDate: "2025-01-21T11:00:00",
      onboardingDate:"2025-01-15T23:59:00",
      courseOverSessionDate: userType === "ranker" ? (newUser ? "2024-11-11T19:00:00" : "") : newUser ? "2024-11-11T19:00:00" : "",
      mockEnablingTime: "2025-01-13T11:00:00",
      mockDisablingTime: "2025-01-19T21:00:00",
      // ?  ======== Configurable dates =======
      email: "admissions.daksh.gurukul.iitg@masaischool.com",
      zoom: "https://us06web.zoom.us/j/88034220522",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-iit-for-all.s3.ap-south-1.amazonaws.com/brochures/DakshGurukul_IITGuwahati_Results_241028.pdf",
      seatBookingAmount: 4000,
      collage: "Daksh Gurukul - IIT Guwahati",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#F7F7FF",
      darkBackgroundColor: "#DBDBFF",
      courseFee: 60000,
      coursePayment: 99,
      title: "Credit-Linked Program in Data Science",
      assesmentpageTitle: "Qualifier Test for Daksh Gurukul, IIT Guwahati's <br/> Credit-Linked Program in Data Science",
      collegeName: "Daksh Gurukul, IIT Guwahati",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/course2_095b9c5095.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Programming", "Mathematics", "Statistics", "Communication Skills"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "3rd Feb",  
      emiTenior: 8,
    },
    IIT_MANDI_BA_19_JAN_25: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["14:00", "15:00"],
      },
      testDate: "2025-01-19T10:00:00",
      testDateTimeOver: "2025-01-19T22:00:00",
      resultDate: "2025-01-24T11:00:00",
      onboardingDate:"2025-01-15T23:59:00",
      courseOverSessionDate: userType === "ranker" ? (newUser ? "2024-11-11T19:00:00" : "") : newUser ? "2024-11-11T19:00:00" : "",
      mockEnablingTime: "2025-01-13T11:00:00",
      mockDisablingTime: "2025-01-19T21:00:00",
      // ?  ======== Configurable dates =======
      email: "admissions.cce.iitmd@masaischool.com",
      yt: "https://youtu.be/O9P9sm0AK4s",
      zoom: "https://us06web.zoom.us/j/88504991222",
      userList: "https://masai-iit-for-all.s3.ap-south-1.amazonaws.com/brochures/CCE_IIT_Mandi_BA_Results_241028.pdf",
      seatBookingAmount: 4000,
      collage: "CCE, IIT Mandi",
      selectColor: "#52BD94",
      borderColor: "#FDC56A",
      backgroundColor: "#FFF9F0",
      courseFee: 60000,
      darkBackgroundColor: "#FEE8C8",
      coursePayment: 99,
      assesmentpageTitle: "Entrance Test for CCE, IIT Mandi's <br/> Minor in Business Analytics Program",
      title: "Minor in Business Analytics",
      collegeName: "CCE, IIT Mandi",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/course1_6549b2bb62.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "100% refund, if you take the test and don't enrol in the program"],
      },
      rankerListData: {
        borderColor: "#FCA311",
        background: "rgba(252, 163, 17, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88504991222",
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Programming", "Basic Maths", "Data Interpretation", "Logical Reasoning"],
      },
      feePaymentDeadline: "3rd Feb",
      emiTenior: 8,
    },


    // ! ======== 26th Jan code ========
    IIT_GUWAHATI_DEV_OPS_26_JAN_25: {
      isCourseOverviewEnabled: true,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["09:00"],
      },
      testDate: "2025-01-26T17:00:00",
      testDateTimeOver: "2025-01-26T22:00:00",
      resultDate: "2025-01-28T11:00:00",
      onboardingDate: "2025-01-28T23:59:00",
      courseOverSessionDate: "2025-01-28T23:59:00",
      mockEnablingTime: "2025-01-20T11:00:00",
      mockDisablingTime: "2025-01-26T21:00:00",
      // ?  ======== Configurable dates =======
      email: "admissions.daksh.gurukul.iitg@masaischool.com",
      zoom: "https://us06web.zoom.us/j/86714976781",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Guwahati",
      selectColor: "#7962DF", // Updated to match IIT_MANDI
      borderColor: "#0E4997", // Updated to match IIT_MANDI
      backgroundColor: "#F8F6FF", // Updated to match IIT_MANDI
      darkBackgroundColor: "#E3DDFA", // Updated to match IIT_MANDI
      courseFee: 100000,
      coursePayment: 99,
      title: "Minor in DevOps",
      assesmentpageTitle: "Entrance Test for IIT Guwahati's <br/> Minor in DevOps",
      collegeName: "IIT Guwahati",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Infinity_1c9a6ab3b2.jpg",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "100% refund, if you take the test and don't enrol in the program"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Basic Programming", "Mathematics", "Arithmetic Aptitude", "Problem Solving Ability"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "10th Feb",
      emiTenior: 6,
    },
    IIT_MANDI_CYBERSECURITY_26_JAN_25: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["09:00"],
      },
      testDate: "2025-01-26T17:00:00",
      testDateTimeOver: "2025-01-26T22:00:00",
      resultDate: "2025-01-29T11:00:00",
      onboardingDate: "2025-01-28T23:59:00",
      courseOverSessionDate: "2025-01-28T23:59:00",
      mockEnablingTime: "2025-01-20T11:00:00",
      mockDisablingTime: "2025-01-26T21:00:00",
      // ?  ======== Configurable dates =======
      email: "support_aiforall@iitrpr.ac.in",
      zoom: "https://us06web.zoom.us/j/82792154343",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Mandi",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#EBEEFE",
      darkBackgroundColor: "#B5BDFC",
      courseFee: 60000,
      coursePayment: 99,
      title: "Minor in Cyber Security & Ethical Hacking",
      assesmentpageTitle: "Entrance Test for IIT Mandi's <br/> Minor in Cyber Security & Ethical Hacking",
      collegeName: "IIT Mandi",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/ShieldCheck.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "100% refund if you don't enroll in the course"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Basic of Programming", "Basics of Networking", "Aptitude"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "10th Feb",
      emiTenior: 8,
    },
    IIT_MANDI_BA_26_JAN_25: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["09:00"],
      },
      testDate: "2025-01-26T17:00:00",
      testDateTimeOver: "2025-01-26T22:00:00",
      resultDate: "2025-01-29T11:00:00",
      onboardingDate:"2025-01-15T23:59:00",
      courseOverSessionDate: "2025-01-27T23:59:00",
      mockEnablingTime: "2025-01-21T11:00:00",
      mockDisablingTime: "2025-01-26T21:00:00",
      // ?  ======== Configurable dates =======
      email: "admissions.cce.iitmd@masaischool.com",
      yt: "https://youtu.be/O9P9sm0AK4s",
      zoom: "https://us06web.zoom.us/j/88504991222",
      userList: "https://masai-iit-for-all.s3.ap-south-1.amazonaws.com/brochures/CCE_IIT_Mandi_BA_Results_241028.pdf",
      seatBookingAmount: 4000,
      collage: "CCE, IIT Mandi",
      selectColor: "#52BD94",
      borderColor: "#FDC56A",
      backgroundColor: "#FFF9F0",
      courseFee: 60000,
      darkBackgroundColor: "#FEE8C8",
      coursePayment: 99,
      assesmentpageTitle: "Entrance Test for CCE, IIT Mandi's <br/> Minor in Business Analytics Program",
      title: "Minor in Business Analytics",
      collegeName: "CCE, IIT Mandi",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/course1_6549b2bb62.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "100% refund, if you take the test and don't enrol in the program"],
      },
      rankerListData: {
        borderColor: "#FCA311",
        background: "rgba(252, 163, 17, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88504991222",
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Programming", "Basic Maths", "Data Interpretation", "Logical Reasoning"],
      },
      feePaymentDeadline: "10rd Feb",
      emiTenior: 8,
    },
    IIT_GUWAHATI_DS_ML_26_JAN_25: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["14:00", "15:00"],
      },
      testDate: "2025-01-26T10:00:00",
      testDateTimeOver: "2025-01-26T22:00:00",
      resultDate: "2025-01-28T11:00:00",
      onboardingDate:"2025-01-15T23:59:00",
      courseOverSessionDate: userType === "ranker" ? (newUser ? "2024-11-11T19:00:00" : "") : newUser ? "2024-11-11T19:00:00" : "",
      mockEnablingTime: "2025-01-13T11:00:00",
      mockDisablingTime: "2025-01-19T21:00:00",
      // ?  ======== Configurable dates =======
      email: "admissions.daksh.gurukul.iitg@masaischool.com",
      zoom: "https://us06web.zoom.us/j/88034220522",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-iit-for-all.s3.ap-south-1.amazonaws.com/brochures/DakshGurukul_IITGuwahati_Results_241028.pdf",
      seatBookingAmount: 4000,
      collage: "Daksh Gurukul - IIT Guwahati",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#F7F7FF",
      darkBackgroundColor: "#DBDBFF",
      courseFee: 60000,
      coursePayment: 99,
      title: "Credit-Linked Program in Data Science",
      assesmentpageTitle: "Qualifier Test for Daksh Gurukul, IIT Guwahati's <br/> Credit-Linked Program in Data Science",
      collegeName: "Daksh Gurukul, IIT Guwahati",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/course2_095b9c5095.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Programming", "Mathematics", "Statistics", "Communication Skills"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "3rd Feb",  
      emiTenior: 8,
    },

    // ! ======== 2th Feb code ========

    IIT_GUWAHATI_DEV_OPS_2_FEB_25: {
      isCourseOverviewEnabled: true,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["09:00"],
      },
      testDate: "2025-02-02T17:00:00",
      testDateTimeOver: "2025-02-02T22:00:00",
      resultDate: "2025-02-08T10:00:00",
      onboardingDate:"2025-02-02T23:59:00",
      courseOverSessionDate: "2025-02-02T23:59:00",
      mockEnablingTime: "2025-01-27T11:00:00",
      mockDisablingTime: "2025-02-02T21:00:00",
      // ?  ======== Configurable dates =======
      email: "admissions.daksh.gurukul.iitg@masaischool.com",
      zoom: "https://us06web.zoom.us/j/86714976781",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Guwahati",
      selectColor: "#7962DF", // Updated to match IIT_MANDI
      borderColor: "#0E4997", // Updated to match IIT_MANDI
      backgroundColor: "#F8F6FF", // Updated to match IIT_MANDI
      darkBackgroundColor: "#E3DDFA", // Updated to match IIT_MANDI
      courseFee: 100000,
      coursePayment: 99,
      title: "Minor in DevOps",
      assesmentpageTitle: "Entrance Test for IIT Guwahati's <br/> Minor in DevOps",
      collegeName: "IIT Guwahati",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Infinity_1c9a6ab3b2.jpg",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "100% refund, if you take the test and don't enrol in the program"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Basic Programming", "Mathematics", "Arithmetic Aptitude", "Problem Solving Ability"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "10th Feb",
      emiTenior: 6,
    },
    IIT_MANDI_CYBERSECURITY_2_FEB_25: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["09:00"],
      },
      testDate: "2025-02-02T17:00:00",
      testDateTimeOver: "2025-02-02T22:00:00",
      resultDate: "2025-02-08T11:00:00",
      onboardingDate:"2025-02-02T23:59:00",
      courseOverSessionDate: "2025-02-02T23:59:00",
      mockEnablingTime: "2025-01-27T11:00:00",
      mockDisablingTime: "2025-02-02T21:00:00",
      // ?  ======== Configurable dates =======
      email: "support_aiforall@iitrpr.ac.in",
      zoom: "https://us06web.zoom.us/j/82792154343",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Mandi",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#EBEEFE",
      darkBackgroundColor: "#B5BDFC",
      courseFee: 60000,
      coursePayment: 99,
      title: "Minor in Cyber Security & Ethical Hacking",
      assesmentpageTitle: "Entrance Test for IIT Mandi's <br/> Minor in Cyber Security & Ethical Hacking",
      collegeName: "IIT Mandi",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/ShieldCheck.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "100% refund if you don't enroll in the course"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Basic of Programming", "Basics of Networking", "Aptitude"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "10th Feb",
      emiTenior: 8,
    },
    IIT_MANDI_BA_2_FEB_25: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["09:00"],
      },
      testDate: "2025-02-02T17:00:00",
      testDateTimeOver: "2025-02-02T22:00:00",
      resultDate: "2025-02-08T11:00:00",
      onboardingDate:"2025-02-02T23:59:00",
      courseOverSessionDate: "2025-02-02T23:59:00",
      mockEnablingTime: "2025-01-27T11:00:00",
      mockDisablingTime: "2025-02-02T21:00:00",
      // ?  ======== Configurable dates =======
      email: "admissions.cce.iitmd@masaischool.com",
      yt: "https://youtu.be/O9P9sm0AK4s",
      zoom: "https://us06web.zoom.us/j/88504991222",
      userList: "https://masai-iit-for-all.s3.ap-south-1.amazonaws.com/brochures/CCE_IIT_Mandi_BA_Results_241028.pdf",
      seatBookingAmount: 4000,
      collage: "CCE, IIT Mandi",
      selectColor: "#52BD94",
      borderColor: "#FDC56A",
      backgroundColor: "#FFF9F0",
      courseFee: 60000,
      darkBackgroundColor: "#FEE8C8",
      coursePayment: 99,
      assesmentpageTitle: "Entrance Test for CCE, IIT Mandi's <br/> Minor in Business Analytics Program",
      title: "Minor in Business Analytics",
      collegeName: "CCE, IIT Mandi",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/course1_6549b2bb62.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "100% refund, if you take the test and don't enrol in the program"],
      },
      rankerListData: {
        borderColor: "#FCA311",
        background: "rgba(252, 163, 17, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88504991222",
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Programming", "Basic Maths", "Data Interpretation", "Logical Reasoning"],
      },
      feePaymentDeadline: "10rd Feb",
      emiTenior: 8,
    },

    // ! ======== 16th Feb code ========
    IIT_GUWAHATI_DEVOPS_16_FEB_25: {
      isCourseOverviewEnabled: true,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["09:00"],
      },
      testDate: "2025-02-16T17:00:00",
      testDateTimeOver: "2025-02-16T22:00:00",
      resultDate: "2025-02-22T11:00:00",
      onboardingDate: "2025-02-16T23:59:00",
      courseOverSessionDate: "2025-02-16T23:59:00",
      mockEnablingTime: "2025-02-03T11:00:00",
      mockDisablingTime: "2025-02-16T21:00:00",
      // ?  ======== Configurable dates =======
      email: "admissions.daksh.gurukul.iitg@masaischool.com",
      zoom: "https://us06web.zoom.us/j/86714976781",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Guwahati",
      selectColor: "#7962DF", // Updated to match IIT_MANDI
      borderColor: "#0E4997", // Updated to match IIT_MANDI
      backgroundColor: "#F8F6FF", // Updated to match IIT_MANDI
      darkBackgroundColor: "#E3DDFA", // Updated to match IIT_MANDI
      courseFee: 100000,
      coursePayment: 99,
      title: "Minor in DevOps",
      assesmentpageTitle: "Entrance Test for IIT Guwahati's <br/> Minor in DevOps",
      collegeName: "IIT Guwahati",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Infinity_1c9a6ab3b2.jpg",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "100% refund, if you take the test and don't enrol in the program"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Basic Programming", "Mathematics", "Arithmetic Aptitude", "Problem Solving Ability"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
      feePaymentDeadline: "10th Feb",
      emiTenior: 6,
    },

  };
  const modifiedData = {
    IIT_MANDI_BA: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="67.852px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_MANDI_BA.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_MANDI_BA.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_MANDI_BA.testDate).format("dddd")} {/*TestTakingDate.format("dddd")*/}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{dayjs(baseData.IIT_MANDI_BA.onboardingDate).format("Do MMM, hh:mmA")}</strong>
          </>,
          <>
            Program Overview Session with Faculty <strong style={{ color: "#3470E4" }}>25th Nov 2024</strong>
          </>,
          <>
            Complete onboarding formalities <strong style={{ color: "#3470E4" }}>26th Nov 2024</strong>
          </>,
          <>
            Inaugural session on <strong style={{ color: "#3470E4" }}>29th Nov 2024</strong>
          </>,
          <>
            Offline orientation in <strong style={{ color: "#3470E4" }}>Jan, 2025</strong>
          </>,
          <>
            Main Classes start on <strong style={{ color: "#3470E4" }}>20th Jan, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "16 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "2 terms & 5 days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/BA_IITMandi_Brochure_V4.pdf",
      },
    },

    IIT_MANDI_ES: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="67.852px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_MANDI_ES.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {/* {TestTakingDate.format("MMMM DD")} */}
              {dayjs(baseData.IIT_MANDI_ES.testDate).format("MMMM DD")}
            </Text>
            , {dayjs(baseData.IIT_MANDI_ES.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },
      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{dayjs(baseData.IIT_MANDI_ES.onboardingDate).format("Do MMM, hh:mmA")}</strong>
          </>,
          <>
            Program Overview Session with Faculty <strong style={{ color: "#3470E4" }}>25th Nov 2024</strong>
          </>,
          <>
            Complete onboarding formalities <strong style={{ color: "#3470E4" }}>26th Nov 2024</strong>
          </>,
          <>
            Inaugural session on <strong style={{ color: "#3470E4" }}>29th Nov 2024</strong>
          </>,
          <>
            Offline orientation in <strong style={{ color: "#3470E4" }}>Jan, 2025</strong>
          </>,
          <>
            Main Classes start on <strong style={{ color: "#3470E4" }}>20th Jan, 2025</strong>
          </>,
        ],
      },
      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "16 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 2-week campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IITMandi_ES_Brochure_New+V2+(1).pdf",
      },
    },
    IIT_GUWAHATI_DS_ML: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_GUWAHATI_DS_ML.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_GUWAHATI_DS_ML.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_GUWAHATI_DS_ML.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"13th Nov, 11:59 PM"}</strong>
          </>,
          <>
            Onboarding formalities by <strong style={{ color: "#3470E4" }}>19th Nov 2024</strong>
          </>,
          <>
            Inaugural session on <strong style={{ color: "#3470E4" }}>22th Nov 2024</strong>
          </>,
          <>
            Foundation classes start <strong style={{ color: "#3470E4" }}>29th Nov, 2024</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>Dec, 2024</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>20nd Jan, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 2-week campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/DS_IITGuwahati_Brochure.pdf",
      },
    },
    IIT_ROPAR: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src={baseData.IIT_ROPAR.logo} display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_ROPAR.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_ROPAR.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_ROPAR.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },
      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"13th Nov, 11:59 PM"}</strong>
          </>,
          <>
            Onboarding formalities by <strong style={{ color: "#3470E4" }}>19th Nov 2024</strong>
          </>,
          <>
            Inaugural session on <strong style={{ color: "#3470E4" }}>22th Nov 2024</strong>
          </>,
          <>
            Foundation classes start <strong style={{ color: "#3470E4" }}>29th Nov, 2024</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>Dec, 2024</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>20nd Jan, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "2 terms & 5-days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI_IITRopar_Brochure_Entrance_Test.pdf",
      },
    },
    IIT_MANDI_V2: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="67.852px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_MANDI_V2.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_MANDI_BA.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_MANDI_BA.testDate).format("dddd")} {/*TestTakingDate.format("dddd")*/}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"13th Nov, 11:59 PM"}</strong>
          </>,
          <>
            Program Overview Session with Faculty <strong style={{ color: "#3470E4" }}>25th Nov 2024</strong>
          </>,
          <>
            Complete onboarding formalities <strong style={{ color: "#3470E4" }}>26th Nov 2024</strong>
          </>,
          <>
            Inaugural session on <strong style={{ color: "#3470E4" }}>29th Nov 2024</strong>
          </>,
          <>
            Offline orientation in <strong style={{ color: "#3470E4" }}>Jan, 2025</strong>
          </>,
          <>
            Main Classes start on <strong style={{ color: "#3470E4" }}>10th Jan, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 5-days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/CSE_IITMandixMasai_Brochure_V7.pdf",
      },
    },

    IIT_ROPAR_V2: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src={baseData.IIT_ROPAR_V2.logo} display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_ROPAR_V2.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_ROPAR_V2.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_ROPAR_V2.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },
      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"13th Nov, 11:59 PM"}</strong>
          </>,
          <>
            Program Overview Session <strong style={{ color: "#3470E4" }}>4th Dec 2024</strong>
          </>,
          <>
            Complete Onboarding Formalities <strong style={{ color: "#3470E4" }}>9th Dec 2024</strong>
          </>,
          <>
            Foundation classes start <strong style={{ color: "#3470E4" }}>23rd December 2024</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>January 2025</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>10th January 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "2 terms & 5-days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI_IITRopar_Brochure_Entrance_Test.pdf",
      },
    },
    IIT_ROPAR_V3: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src={baseData.IIT_ROPAR_V3.logo} display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_ROPAR_V3.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_ROPAR_V3.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_ROPAR_V3.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },
      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"13th Nov, 11:59 PM"}</strong>
          </>,
          <>
            Program Overview Session <strong style={{ color: "#3470E4" }}>4th Dec 2024</strong>
          </>,
          <>
            Complete Onboarding Formalities <strong style={{ color: "#3470E4" }}>9th Dec 2024</strong>
          </>,
          <>
            Foundation classes start <strong style={{ color: "#3470E4" }}>23rd December 2024</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>January 2025</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>10th January 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "2 terms & 5-days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI_IITRopar_Brochure_Entrance_Test.pdf",
      },
    },

    IIT_GUWAHATI_DS_ML_V2: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_GUWAHATI_DS_ML_V2.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_GUWAHATI_DS_ML_V2.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_GUWAHATI_DS_ML_V2.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"13th Nov, 11:59 PM"}</strong>
          </>,
          <>
            Program Overview Session <strong style={{ color: "#3470E4" }}>3rd Dec 2024</strong>
          </>,
          <>
            Complete Onboarding Formalities <strong style={{ color: "#3470E4" }}>09th Dec 2024</strong>
          </>,
          <>
            Foundation Classes Start <strong style={{ color: "#3470E4" }}>23rd Dec, 2024</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>Jan, 2024</strong>
          </>,
          <>
            Batch Start on <strong style={{ color: "#3470E4" }}>20nd Jan, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 2-week campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IITG_Credit_linked_DS.pdf",
      },
    },
    IIT_MANDI_BA_V2: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="67.852px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_MANDI_BA_V2.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_MANDI_BA_V2.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_MANDI_BA_V2.testDate).format("dddd")} {/*TestTakingDate.format("dddd")*/}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{dayjs(baseData.IIT_MANDI_BA_V2.onboardingDate).format("Do MMM, hh:mmA")}</strong>
          </>,
          <>
            Program Overview Session with Faculty <strong style={{ color: "#3470E4" }}>25th Nov 2024</strong>
          </>,
          <>
            Complete onboarding formalities <strong style={{ color: "#3470E4" }}>26th Nov 2024</strong>
          </>,
          <>
            Inaugural session on <strong style={{ color: "#3470E4" }}>29th Nov 2024</strong>
          </>,
          <>
            Offline orientation in <strong style={{ color: "#3470E4" }}>Jan, 2025</strong>
          </>,
          <>
            Main Classes start on <strong style={{ color: "#3470E4" }}>20th Jan, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "16 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "2 terms & 5 days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/BA_IITMandi_Brochure_V4.pdf",
      },
    },
    // ? ========= Course launch on 1st Dec =========
    IIT_MANDI_V3: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="67.852px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_MANDI_V3.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_MANDI_V3.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_MANDI_V3.testDate).format("dddd")} {/*TestTakingDate.format("dddd")*/}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Program Overview Session with Faculty <strong style={{ color: "#3470E4" }}>10th Dec 2024</strong>
          </>,
          <>
            Complete onboarding formalities <strong style={{ color: "#3470E4" }}>16th Dec 2024</strong>
          </>,
          <>
            Foundation classes start <strong style={{ color: "#3470E4" }}>23rd December 2024</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>Jan 2025</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>10th Jan 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 5-days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/CSE_IITMandixMasai_Brochure_V8.pdf",
      },
    },
    IIT_ROPAR_V4: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src={baseData.IIT_ROPAR_V4.logo} display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_ROPAR_V4.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_ROPAR_V4.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_ROPAR_V4.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },
      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Program Overview Session <strong style={{ color: "#3470E4" }}>9th Dec 2024</strong>
          </>,
          <>
            Complete Onboarding Formalities <strong style={{ color: "#3470E4" }}>16th Dec 2024</strong>
          </>,
          <>
            Foundation classes start <strong style={{ color: "#3470E4" }}>23rd December 2024</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>Jan 2025</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>20th Jan 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 5-days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/A4_IITRoparxMasai_Brochure_AI_Management.pdf",
      },
    },
    IIT_ROPAR_CYBER_SECURITY: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src={baseData.IIT_ROPAR_CYBER_SECURITY.logo} display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_ROPAR_CYBER_SECURITY.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_ROPAR_CYBER_SECURITY.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_ROPAR_CYBER_SECURITY.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },
      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Program Overview Session <strong style={{ color: "#3470E4" }}>11th Dec 2024</strong>
          </>,
          <>
            Complete Onboarding Formalities <strong style={{ color: "#3470E4" }}>16th Dec 2024</strong>
          </>,
          <>
            Foundation classes start <strong style={{ color: "#3470E4" }}>06th Jan 2024</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>Feb 2025</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>17th Feb 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 5-days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IITRopar_Brochure_CyberSecurity%26EthicalHacking.pdf",
      },
    },

    IIT_GUWAHATI_CSE: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_GUWAHATI_CSE.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_GUWAHATI_CSE.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_GUWAHATI_CSE.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Program Overview Session <strong style={{ color: "#3470E4" }}>10th Dec 2024</strong>
          </>,
          <>
            Complete Onboarding Formalities <strong style={{ color: "#3470E4" }}>16th Dec 2024</strong>
          </>,
          <>
            Foundation Classes Start <strong style={{ color: "#3470E4" }}>23rd Dec 2024</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>Feb 2025</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>17th Feb 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "24 program credits" },
          { icon: LuClock3, text: "12 months online program" },
          { icon: PiSparkle, text: "3 terms & 2-week campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IITG_CSE%26AI_Brochure_V1.pdf",
      },
    },
    // ! ======== 8th Dec code ========

    IIT_MANDI_BA_8_DEC_24: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="67.852px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_MANDI_BA_8_DEC_24.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_MANDI_BA_8_DEC_24.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_MANDI_BA_8_DEC_24.testDate).format("dddd")} {/*TestTakingDate.format("dddd")*/}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{dayjs(baseData.IIT_MANDI_BA_8_DEC_24.onboardingDate).format("Do MMM, hh:mmA")}</strong>
          </>,
          <>
            Program Overview Session with Faculty on <strong style={{ color: "#3470E4" }}>17th Dec 2024</strong>
          </>,
          <>
            Complete Onboarding Formalities <strong style={{ color: "#3470E4" }}>23rd Dec 2024</strong>
          </>,
          <>
            Foundation classes start on <strong style={{ color: "#3470E4" }}>23rd Dec 2024</strong>
          </>,
          <>
            Offline orientation in <strong style={{ color: "#3470E4" }}>February, 2025</strong>
          </>,
          <>
            Main Classes start on <strong style={{ color: "#3470E4" }}>20th Jan, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "16 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "2 terms & 5 days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/BA_IITMandi_Brochure_V4.pdf",
      },
    },
    IIT_GUWAHATI_DS_8_DEC_24: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_GUWAHATI_DS_8_DEC_24.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_GUWAHATI_DS_8_DEC_24.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_GUWAHATI_DS_8_DEC_24.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"20th Dec, 11:59 PM"}</strong>
          </>,
          <>
            Program Overview Session with Faculty <strong style={{ color: "#3470E4" }}>17th Dec 2024</strong>
          </>,
          <>
            Complete Onboarding Formalities <strong style={{ color: "#3470E4" }}>23rd Dec 2024</strong>
          </>,
          <>
            Foundation Classes Start <strong style={{ color: "#3470E4" }}>23rd Dec 2024</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>February, 2025</strong>
          </>,
          <>
            Batch Start on <strong style={{ color: "#3470E4" }}>20th Jan, 2025</strong>
          </>,
          <>
            Inaugural Session on <strong style={{ color: "#3470E4" }}>20th Jan, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 2-week campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IITG_Credit_linked_DS.pdf",
      },
    },
    IIT_MANDI_ES_8_DEC_24: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="67.852px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_MANDI_ES_8_DEC_24.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {/* {TestTakingDate.format("MMMM DD")} */}
              {dayjs(baseData.IIT_MANDI_ES_8_DEC_24.testDate).format("MMMM DD")}
            </Text>
            , {dayjs(baseData.IIT_MANDI_ES_8_DEC_24.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },
      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by <strong style={{ color: "#3470E4", marginLeft: "5px" }}>20th Dec, 11:59 PM</strong>
          </>,
          <>
            Program Overview Session with Faculty <strong style={{ color: "#3470E4" }}>17th Dec 2024</strong>
          </>,
          <>
            Complete Onboarding Formalities <strong style={{ color: "#3470E4" }}>23rd Dec 2024</strong>
          </>,
          <>
            Foundation Classes Start <strong style={{ color: "#3470E4" }}>23rd Dec 2024</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>February, 2025</strong>
          </>,
          <>
            Batch Start on <strong style={{ color: "#3470E4" }}>20th Jan, 2025</strong>
          </>,
          <>
            Inaugural Session on <strong style={{ color: "#3470E4" }}>20th Jan, 2025</strong>
          </>,
        ],
      },
      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "16 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 2-week campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IITMandi_ES_Brochure_New+V2+(1).pdf",
      },
    },
    IIT_MANG_COMPETITIVE_PROGRAMMING_8_DEC_24: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            by
          </Text>{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            IIT Professors
          </Text>{" "}
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            and
          </Text>{" "}
          <Image maxW="98px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/image+168.png" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            Industry Experts
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>40 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {/* {TestTakingDate.format("MMMM DD")} */}
              {dayjs(baseData.IIT_MANG_COMPETITIVE_PROGRAMMING_8_DEC_24.testDate).format("MMMM DD")}
            </Text>
            , {dayjs(baseData.IIT_MANG_COMPETITIVE_PROGRAMMING_8_DEC_24.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },
      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Platform Onboarding by <strong style={{ color: "#3470E4", marginLeft: "5px" }}>19th Dec 2024</strong>
          </>,
          <>
            Program Overview Session with Faculty <strong style={{ color: "#3470E4" }}>16th Dec 2024</strong>
          </>,
          <>
            Complete Onboarding Formalities <strong style={{ color: "#3470E4" }}>23rd Dec 2024</strong>
          </>,
          <>
            Course Fee Payment Date <strong style={{ color: "#3470E4" }}>23rd Dec 2024</strong>
          </>,
          <>
            Main Classes Start <strong style={{ color: "#3470E4" }}>23rd Dec 2024</strong>
          </>,
          <>
            Inaugural Session <strong style={{ color: "#3470E4" }}>23rd Dec 2024</strong>
          </>,
        ],
      },
      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "16 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 2-week campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IITMandi_ES_Brochure_New+V2+(1).pdf",
      },
    },

    // ! ======== 15th Dec code ========
    IIT_GUWAHATI_DEV_OPS_15_DEC_24: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_GUWAHATI_DEV_OPS_15_DEC_24.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_GUWAHATI_DEV_OPS_15_DEC_24.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_GUWAHATI_DEV_OPS_15_DEC_24.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"20th Dec, 11:59 PM"}</strong>
          </>,
          <>
            Complete Onboarding Formalities <strong style={{ color: "#3470E4" }}>30th Dec 2024</strong>
          </>,
          <>
            Program Overview Session <strong style={{ color: "#3470E4" }}>3rd Jan 2025</strong>
          </>,
          <>
            Foundation Classes Start <strong style={{ color: "#3470E4" }}>6th Jan 2025</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>March, 2025</strong>
          </>,
          <>
            Batch Start on <strong style={{ color: "#3470E4" }}>24th Feb, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: LuClock3, text: "06 months online program" },
          { icon: PiSparkle, text: "3 terms & 5 days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IITG_Credit_linked_DS.pdf",
      },
    },
    IIT_MANG_ADVANCE_BACKEND_15_DEC_24: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            by
          </Text>{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            IIT Professors
          </Text>
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            and
          </Text>{" "}
          <Image maxW="67.852px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/image_168_bdf64147b0.png" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_MANG_ADVANCE_BACKEND_15_DEC_24.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {/* {TestTakingDate.format("MMMM DD")} */}
              {dayjs(baseData.IIT_MANG_ADVANCE_BACKEND_15_DEC_24.testDate).format("MMMM DD")}
            </Text>
            , {dayjs(baseData.IIT_MANG_ADVANCE_BACKEND_15_DEC_24.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },
      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by <strong style={{ color: "#3470E4", marginLeft: "5px" }}>20th Dec, 11:59 PM</strong>
          </>,
          <>
            Complete Onboarding Formalities <strong style={{ color: "#3470E4" }}>28rd Dec 2024</strong>
          </>,
          <>
            Batch Start on <strong style={{ color: "#3470E4" }}>20th Jan, 2025</strong>
          </>,
        ],
      },
      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "16 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 2-week campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IITMandi_ES_Brochure_New+V2+(1).pdf",
      },
    },
    IIT_ROPAR_AI_CSE_15_DEC_24: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src={baseData.IIT_ROPAR_AI_CSE_15_DEC_24.logo} display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_ROPAR_AI_CSE_15_DEC_24.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_ROPAR_AI_CSE_15_DEC_24.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_ROPAR_AI_CSE_15_DEC_24.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },
      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"17th Dec, 11:59 PM"}</strong>
          </>,
          <>
            Program Overview Session <strong style={{ color: "#3470E4" }}>26th Dec 2024</strong>
          </>,
          <>
            Complete Onboarding Formalities <strong style={{ color: "#3470E4" }}>30th Dec 2024</strong>
          </>,
          <>
            Foundation classes start <strong style={{ color: "#3470E4" }}>23rd December 2024</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>February 2025</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>20th January 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "2 terms & 5-days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI_IITRopar_Brochure_Entrance_Test.pdf",
      },
    },
    IIT_ROPAR_AI_NONTECH_15_DEC_24: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src={baseData.IIT_ROPAR_AI_NONTECH_15_DEC_24.logo} display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_ROPAR_AI_NONTECH_15_DEC_24.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_ROPAR_AI_NONTECH_15_DEC_24.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_ROPAR_AI_NONTECH_15_DEC_24.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },
      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"17th Dec, 11:59 PM"}</strong>
          </>,
          <>
            Program Overview Session <strong style={{ color: "#3470E4" }}>26th Dec 2024</strong>
          </>,
          <>
            Complete Onboarding Formalities <strong style={{ color: "#3470E4" }}>30th Dec 2024</strong>
          </>,
          <>
            Foundation classes start <strong style={{ color: "#3470E4" }}>23rd December 2024</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>February 2025</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>20th January 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "2 terms & 5-days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI_IITRopar_Brochure_Entrance_Test.pdf",
      },
    },

    // ! Manually created dummy course
    IIT_MANDI_DUMMY_TEST_12_DEC: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="67.852px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_MANDI_DUMMY_TEST_12_DEC.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_MANDI_DUMMY_TEST_12_DEC.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_MANDI_DUMMY_TEST_12_DEC.testDate).format("dddd")} {/*TestTakingDate.format("dddd")*/}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"31st Dec, 11:59 PM"}</strong>
          </>,
          <>
            Program Overview Session with Faculty <strong style={{ color: "#3470E4" }}>8th Jan 2024</strong>
          </>,
          <>
            Complete onboarding formalities <strong style={{ color: "#3470E4" }}>8th Jan 2024</strong>
          </>,
          <>
            Foundation classes start <strong style={{ color: "#3470E4" }}>23rd December 2024</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>February 2025</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>10th Jan 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 5-days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/CSE_IITMandixMasai_Brochure_V8.pdf",
      },
    },

    IIT_GUWAHATI_DS_ML_22_DEC_24: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_GUWAHATI_DS_ML_22_DEC_24.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_GUWAHATI_DS_ML_22_DEC_24.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_GUWAHATI_DS_ML_22_DEC_24.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"20th Dec, 11:59 PM"}</strong>
          </>,
          <>
            Program Overview Session with Faculty <strong style={{ color: "#3470E4" }}>7th Jan 2025</strong>
          </>,
          <>
            Complete Onboarding Formalities <strong style={{ color: "#3470E4" }}>6th Jan 2024</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>January, 2025</strong>
          </>,
          <>
            Batch Start on <strong style={{ color: "#3470E4" }}>20th Jan, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 2-week campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IITG_Credit_linked_DS.pdf",
      },
    },

    // ? ========= Course launch on 29th Dec =========
    IIT_MANDI_CSE_29_DEC_24: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="67.852px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_MANDI_CSE_29_DEC_24.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_MANDI_CSE_29_DEC_24.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_MANDI_CSE_29_DEC_24.testDate).format("dddd")} {/*TestTakingDate.format("dddd")*/}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by <strong style={{ color: "#3470E4" }}>8th Jan 2025</strong>
          </>,
          <>
            Program Overview Session with Faculty <strong style={{ color: "#3470E4" }}>8th Jan 2025</strong>
          </>,
          <>
            Complete onboarding formalities <strong style={{ color: "#3470E4" }}>9th Jan 2025</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>February, 2025</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>10th Jan 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 5-days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/CSE_IITMandixMasai_Brochure_V8.pdf",
      },
    },
    // ! ======== 5th Jan code ========

    IIT_ROPAR_AI_CSE_5_JAN_25: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src={baseData.IIT_ROPAR_AI_CSE_15_DEC_24.logo} display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_ROPAR_AI_CSE_15_DEC_24.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_ROPAR_AI_CSE_5_JAN_25.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_ROPAR_AI_CSE_5_JAN_25.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },
      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"17th Dec, 11:59 PM"}</strong>
          </>,
          <>
            Program Overview Session <strong style={{ color: "#3470E4" }}>14th Jan 2025</strong>
          </>,
          <>
            Complete Onboarding Formalities <strong style={{ color: "#3470E4" }}>20th Jan 2025</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>February 2025</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>20th January 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "2 terms & 5-days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI_IITRopar_Brochure_Entrance_Test.pdf",
      },
    },
    IIT_ROPAR_AI_MANAGEMENT_5_JAN_25: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src={baseData.IIT_ROPAR_AI_NONTECH_15_DEC_24.logo} display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_ROPAR_AI_NONTECH_15_DEC_24.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_ROPAR_AI_MANAGEMENT_5_JAN_25.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_ROPAR_AI_MANAGEMENT_5_JAN_25.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },
      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"17th Dec, 11:59 PM"}</strong>
          </>,
          <>
            Program Overview Session <strong style={{ color: "#3470E4" }}>14th Jan 2025</strong>
          </>,
          <>
            Complete Onboarding Formalities <strong style={{ color: "#3470E4" }}>20th Jan 2025</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>February 2025</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>20th January 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "2 terms & 5-days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI_IITRopar_Brochure_Entrance_Test.pdf",
      },
    },
    IIT_MANDI_CSE_05_JAN_25: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="67.852px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_MANDI_CSE_05_JAN_25.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_MANDI_CSE_05_JAN_25.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_MANDI_CSE_05_JAN_25.testDate).format("dddd")} {/*TestTakingDate.format("dddd")*/}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by <strong style={{ color: "#3470E4" }}>8th Jan 2025</strong>
          </>,
          <>
            Program Overview Session with Faculty <strong style={{ color: "#3470E4" }}>7th Jan 2025</strong>
          </>,
          <>
            Complete onboarding formalities <strong style={{ color: "#3470E4" }}>9th Jan 2025</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>February, 2025</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>10th Jan 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 5-days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/CSE_IITMandixMasai_Brochure_V8.pdf",
      },
    },

    // ! ======== 12th Jan code ========
    IIT_ROPAR_AI_CSE_12_JAN_25: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src={baseData.IIT_ROPAR_AI_CSE_12_JAN_25.logo} display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_ROPAR_AI_CSE_12_JAN_25.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_ROPAR_AI_CSE_12_JAN_25.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_ROPAR_AI_CSE_12_JAN_25.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },
      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"17th Dec, 11:59 PM"}</strong>
          </>,
          <>
            Complete Onboarding Formalities <strong style={{ color: "#3470E4" }}>27th Jan 2025</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>February 2025</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>20th January 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "2 terms & 5-days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI_IITRopar_Brochure_Entrance_Test.pdf",
      },
    },
    IIT_ROPAR_AI_NONTECH_12_JAN_25: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src={baseData.IIT_ROPAR_AI_NONTECH_12_JAN_25.logo} display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_ROPAR_AI_NONTECH_12_JAN_25.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_ROPAR_AI_NONTECH_12_JAN_25.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_ROPAR_AI_NONTECH_12_JAN_25.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },
      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"17th Dec, 11:59 PM"}</strong>
          </>,
          <>
            Complete Onboarding Formalities <strong style={{ color: "#3470E4" }}>27th Jan 2025</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>February 2025</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>20th January 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "2 terms & 5-days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI_IITRopar_Brochure_Entrance_Test.pdf",
      },
    },
    IIT_GUWAHATI_DS_ML_12_JAN_25: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_GUWAHATI_DS_ML_12_JAN_25.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_GUWAHATI_DS_ML_12_JAN_25.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_GUWAHATI_DS_ML_12_JAN_25.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
         points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"17th Dec, 11:59 PM"}</strong>
          </>,
          <>
            Complete Onboarding Formalities <strong style={{ color: "#3470E4" }}>27th Jan 2025</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>February 2025</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>20th January 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 2-week campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IITG_Credit_linked_DS.pdf",
      },
    },
    IIT_MANDI_BA_12_JAN_25: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="67.852px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_MANDI_BA_12_JAN_25.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_MANDI_BA_12_JAN_25.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_MANDI_BA_12_JAN_25.testDate).format("dddd")} {/*TestTakingDate.format("dddd")*/}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
         points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"17th Dec, 11:59 PM"}</strong>
          </>,
          <>
            Complete Onboarding Formalities <strong style={{ color: "#3470E4" }}>27th Jan 2025</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>February 2025</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>10th February 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "16 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "2 terms & 5 days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/BA_IITMandi_Brochure_V4.pdf",
      },
    },
    // ! ======== 19th Jan code ========
    IIT_ROPAR_AI_CSE_19_JAN_25: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src={baseData.IIT_ROPAR_AI_CSE_12_JAN_25.logo} display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_ROPAR_AI_CSE_12_JAN_25.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_ROPAR_AI_CSE_19_JAN_25.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_ROPAR_AI_CSE_19_JAN_25.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },
      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"20th Dec, 11:59 PM"}</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>February, 2025</strong>
          </>,
          <>
            Batch Start on <strong style={{ color: "#3470E4" }}>24th Jan, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "2 terms & 5-days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI_IITRopar_Brochure_Entrance_Test.pdf",
      },
    },
    IIT_ROPAR_AI_NONTECH_19_JAN_25: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src={baseData.IIT_ROPAR_AI_NONTECH_19_JAN_25.logo} display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_ROPAR_AI_NONTECH_12_JAN_25.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_ROPAR_AI_NONTECH_19_JAN_25.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_ROPAR_AI_NONTECH_19_JAN_25.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },
      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"20th Dec, 11:59 PM"}</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>February, 2025</strong>
          </>,
          <>
            Batch Start on <strong style={{ color: "#3470E4" }}>24th Jan, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "2 terms & 5-days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI_IITRopar_Brochure_Entrance_Test.pdf",
      },
    },
    IIT_GUWAHATI_DS_ML_19_JAN_25: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_GUWAHATI_DS_ML_19_JAN_25.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_GUWAHATI_DS_ML_19_JAN_25.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_GUWAHATI_DS_ML_19_JAN_25.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"20th Dec, 11:59 PM"}</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>February, 2025</strong>
          </>,
          <>
            Batch Start on <strong style={{ color: "#3470E4" }}>25th Jan, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 2-week campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IITG_Credit_linked_DS.pdf",
      },
    },
    IIT_MANDI_BA_19_JAN_25: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="67.852px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_MANDI_BA_19_JAN_25.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_MANDI_BA_19_JAN_25.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_MANDI_BA_19_JAN_25.testDate).format("dddd")} {/*TestTakingDate.format("dddd")*/}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{dayjs(baseData.IIT_MANDI_BA_19_JAN_25.onboardingDate).format("Do MMM, hh:mmA")}</strong>
          </>,
          <>
            Program Overview Session with Faculty on <strong style={{ color: "#3470E4" }}>30th Jan 2025</strong>
          </>,
          <>
            Offline orientation in <strong style={{ color: "#3470E4" }}>February, 2025</strong>
          </>,
          <>
            Main Classes start on <strong style={{ color: "#3470E4" }}>10th Feb, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "16 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "2 terms & 5 days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/BA_IITMandi_Brochure_V4.pdf",
      },
    },

    // ! ======== 26th Jan code ========
    IIT_GUWAHATI_DEV_OPS_26_JAN_25: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_GUWAHATI_DEV_OPS_26_JAN_25.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_GUWAHATI_DEV_OPS_26_JAN_25.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_GUWAHATI_DEV_OPS_26_JAN_25.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"20th Dec, 11:59 PM"}</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>April, 2025</strong>
          </>,
          <>
            Batch Start on <strong style={{ color: "#3470E4" }}>24th Feb, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: LuClock3, text: "06 months online program" },
          { icon: PiSparkle, text: "3 terms & 5 days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IITG_Credit_linked_DS.pdf",
      },
    },
    IIT_MANDI_CYBERSECURITY_26_JAN_25: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src={baseData.IIT_MANDI_CYBERSECURITY_26_JAN_25.logo} display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_MANDI_CYBERSECURITY_26_JAN_25.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_MANDI_CYBERSECURITY_26_JAN_25.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_MANDI_CYBERSECURITY_26_JAN_25.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },
      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Program Overview Session <strong style={{ color: "#3470E4" }}>11th Dec 2024</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>April 2025</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>17th Feb 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 5-days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IITRopar_Brochure_CyberSecurity%26EthicalHacking.pdf",
      },
    },
    IIT_MANDI_BA_26_JAN_25: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="67.852px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_MANDI_BA_26_JAN_25.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_MANDI_BA_26_JAN_25.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_MANDI_BA_26_JAN_25.testDate).format("dddd")} {/*TestTakingDate.format("dddd")*/}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{dayjs(baseData.IIT_MANDI_BA_26_JAN_25.onboardingDate).format("Do MMM, hh:mmA")}</strong>
          </>,
          <>
            Offline orientation in <strong style={{ color: "#3470E4" }}>April, 2025</strong>
          </>,
          <>
            Main Classes start on <strong style={{ color: "#3470E4" }}>10th Feb, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "16 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "2 terms & 5 days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/BA_IITMandi_Brochure_V4.pdf",
      },
    },
    IIT_GUWAHATI_DS_ML_26_JAN_25: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_GUWAHATI_DS_ML_19_JAN_25.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_GUWAHATI_DS_ML_19_JAN_25.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_GUWAHATI_DS_ML_19_JAN_25.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"20th Dec, 11:59 PM"}</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>10th April, 2025</strong>
          </>,
          <>
            Batch Start on <strong style={{ color: "#3470E4" }}>10th April, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 2-week campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IITG_Credit_linked_DS.pdf",
      },
    },
    // ! ======== 2nd Feb code ========
    IIT_GUWAHATI_DEV_OPS_2_FEB_25: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_GUWAHATI_DEV_OPS_2_FEB_25.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_GUWAHATI_DEV_OPS_2_FEB_25.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_GUWAHATI_DEV_OPS_2_FEB_25.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"20th Dec, 11:59 PM"}</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>April, 2025</strong>
          </>,
          <>
            Batch Start on <strong style={{ color: "#3470E4" }}>24th March, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: LuClock3, text: "06 months online program" },
          { icon: PiSparkle, text: "3 terms & 5 days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IITG_Credit_linked_DS.pdf",
      },
    },
    IIT_MANDI_CYBERSECURITY_2_FEB_25: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src={baseData.IIT_MANDI_CYBERSECURITY_2_FEB_25.logo} display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_MANDI_CYBERSECURITY_2_FEB_25.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_MANDI_CYBERSECURITY_2_FEB_25.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_MANDI_CYBERSECURITY_2_FEB_25.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },
      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Program Overview Session <strong style={{ color: "#3470E4" }}>11th Dec 2024</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>April 2025</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>17th Feb 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 5-days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IITRopar_Brochure_CyberSecurity%26EthicalHacking.pdf",
      },
    },
    IIT_MANDI_BA_2_FEB_25: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="67.852px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_MANDI_BA_2_FEB_25.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_MANDI_BA_2_FEB_25.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_MANDI_BA_2_FEB_25.testDate).format("dddd")} {/*TestTakingDate.format("dddd")*/}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{dayjs(baseData.IIT_MANDI_BA_2_FEB_25.onboardingDate).format("Do MMM, hh:mmA")}</strong>
          </>,
          <>
            Offline orientation in <strong style={{ color: "#3470E4" }}>April, 2025</strong>
          </>,
          <>
            Main Classes start on <strong style={{ color: "#3470E4" }}>10th Feb, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "16 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "2 terms & 5 days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/BA_IITMandi_Brochure_V4.pdf",
      },
    },

    // ! ======== 16th Feb code ========
    IIT_GUWAHATI_DEVOPS_16_FEB_25: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_GUWAHATI_DEVOPS_16_FEB_25.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_GUWAHATI_DEVOPS_16_FEB_25.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_GUWAHATI_DEVOPS_16_FEB_25.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"20th Dec, 11:59 PM"}</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>April, 2025</strong>
          </>,
          <>
            Batch Start on <strong style={{ color: "#3470E4" }}>24th Feb, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: LuClock3, text: "06 months online program" },
          { icon: PiSparkle, text: "3 terms & 5 days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IITG_Credit_linked_DS.pdf",
      },
    },
  };

  return {
    IIT_MANDI_BA: { ...baseData.IIT_MANDI_BA, ...modifiedData.IIT_MANDI_BA },
    IIT_MANDI_ES: { ...baseData.IIT_MANDI_ES, ...modifiedData.IIT_MANDI_ES },
    IIT_GUWAHATI_DS_ML: { ...baseData.IIT_GUWAHATI_DS_ML, ...modifiedData.IIT_GUWAHATI_DS_ML },
    IIT_ROPAR: { ...baseData.IIT_ROPAR, ...modifiedData.IIT_ROPAR },
    IIT_MANDI_V2: { ...baseData.IIT_MANDI_V2, ...modifiedData.IIT_MANDI_V2 },
    IIT_GUWAHATI_DS_ML_V2: { ...baseData.IIT_GUWAHATI_DS_ML_V2, ...modifiedData.IIT_GUWAHATI_DS_ML_V2 },
    IIT_ROPAR_V2: { ...baseData.IIT_ROPAR_V2, ...modifiedData.IIT_ROPAR_V2 },
    IIT_ROPAR_V3: { ...baseData.IIT_ROPAR_V3, ...modifiedData.IIT_ROPAR_V3 },

    // ? ========= Course launch on 1st Dec ==========
    IIT_ROPAR_V4: { ...baseData.IIT_ROPAR_V4, ...modifiedData.IIT_ROPAR_V4 },
    IIT_MANDI_V3: { ...baseData.IIT_MANDI_V3, ...modifiedData.IIT_MANDI_V3 },
    IIT_ROPAR_CYBER_SECURITY: { ...baseData.IIT_ROPAR_CYBER_SECURITY, ...modifiedData.IIT_ROPAR_CYBER_SECURITY },
    IIT_MANDI_BA_V2: { ...baseData.IIT_MANDI_BA_V2, ...modifiedData.IIT_MANDI_BA_V2 },
    IIT_GUWAHATI_CSE: { ...baseData.IIT_GUWAHATI_CSE, ...modifiedData.IIT_GUWAHATI_CSE },
    // ! ======== 8th Dec code ========
    IIT_MANDI_BA_8_DEC_24: { ...baseData.IIT_MANDI_BA_8_DEC_24, ...modifiedData.IIT_MANDI_BA_8_DEC_24 },
    IIT_GUWAHATI_DS_8_DEC_24: { ...baseData.IIT_GUWAHATI_DS_8_DEC_24, ...modifiedData.IIT_GUWAHATI_DS_8_DEC_24 },
    IIT_MANDI_ES_8_DEC_24: { ...baseData.IIT_MANDI_ES_8_DEC_24, ...modifiedData.IIT_MANDI_ES_8_DEC_24 },
    IIT_MANG_COMPETITIVE_PROGRAMMING_8_DEC_24: { ...baseData.IIT_MANG_COMPETITIVE_PROGRAMMING_8_DEC_24, ...modifiedData.IIT_MANG_COMPETITIVE_PROGRAMMING_8_DEC_24 },

    // ! ======== 15th Dec code ========
    IIT_GUWAHATI_DEV_OPS_15_DEC_24: { ...baseData.IIT_GUWAHATI_DEV_OPS_15_DEC_24, ...modifiedData.IIT_GUWAHATI_DEV_OPS_15_DEC_24 },
    IIT_MANG_ADVANCE_BACKEND_15_DEC_24: { ...baseData.IIT_MANG_ADVANCE_BACKEND_15_DEC_24, ...modifiedData.IIT_MANG_ADVANCE_BACKEND_15_DEC_24 },
    IIT_ROPAR_AI_CSE_15_DEC_24: { ...baseData.IIT_ROPAR_AI_CSE_15_DEC_24, ...modifiedData.IIT_ROPAR_AI_CSE_15_DEC_24 },
    IIT_ROPAR_AI_NONTECH_15_DEC_24: { ...baseData.IIT_ROPAR_AI_NONTECH_15_DEC_24, ...modifiedData.IIT_ROPAR_AI_NONTECH_15_DEC_24 },

    // ! Manually created dummy course
    IIT_MANDI_DUMMY_TEST_12_DEC: { ...baseData.IIT_MANDI_DUMMY_TEST_12_DEC, ...modifiedData.IIT_MANDI_DUMMY_TEST_12_DEC },
    // ! ======== 22nd Dec code ========
    IIT_GUWAHATI_DS_ML_22_DEC_24: { ...baseData.IIT_GUWAHATI_DS_ML_22_DEC_24, ...modifiedData.IIT_GUWAHATI_DS_ML_22_DEC_24 },
    // ! ======== 29th Dec code ========
    IIT_MANDI_CSE_29_DEC_24: { ...baseData.IIT_MANDI_CSE_29_DEC_24, ...modifiedData.IIT_MANDI_CSE_29_DEC_24 },

    // ! ======== 5th Jan code ========

    IIT_ROPAR_AI_CSE_5_JAN_25: { ...baseData.IIT_ROPAR_AI_CSE_5_JAN_25, ...modifiedData.IIT_ROPAR_AI_CSE_5_JAN_25 },
    IIT_ROPAR_AI_MANAGEMENT_5_JAN_25: { ...baseData.IIT_ROPAR_AI_MANAGEMENT_5_JAN_25, ...modifiedData.IIT_ROPAR_AI_MANAGEMENT_5_JAN_25 },
    IIT_MANDI_CSE_05_JAN_25: { ...baseData.IIT_MANDI_CSE_05_JAN_25, ...modifiedData.IIT_MANDI_CSE_05_JAN_25 },
    // ! ======== 12th Jan code ========
    IIT_MANDI_BA_12_JAN_25: { ...baseData.IIT_MANDI_BA_12_JAN_25, ...modifiedData.IIT_MANDI_BA_12_JAN_25 },
    IIT_GUWAHATI_DS_ML_12_JAN_25: { ...baseData.IIT_GUWAHATI_DS_ML_12_JAN_25, ...modifiedData.IIT_GUWAHATI_DS_ML_12_JAN_25 },
    IIT_ROPAR_AI_CSE_12_JAN_25: { ...baseData.IIT_ROPAR_AI_CSE_12_JAN_25, ...modifiedData.IIT_ROPAR_AI_CSE_12_JAN_25 },
    IIT_ROPAR_AI_NONTECH_12_JAN_25: { ...baseData.IIT_ROPAR_AI_NONTECH_12_JAN_25, ...modifiedData.IIT_ROPAR_AI_NONTECH_12_JAN_25 },

    // ! ======== 19th Jan code ========
    IIT_MANDI_BA_19_JAN_25: { ...baseData.IIT_MANDI_BA_19_JAN_25, ...modifiedData.IIT_MANDI_BA_19_JAN_25 },
  IIT_GUWAHATI_DS_ML_19_JAN_25: { ...baseData.IIT_GUWAHATI_DS_ML_19_JAN_25, ...modifiedData.IIT_GUWAHATI_DS_ML_19_JAN_25 },
  IIT_ROPAR_AI_CSE_19_JAN_25: { ...baseData.IIT_ROPAR_AI_CSE_19_JAN_25, ...modifiedData.IIT_ROPAR_AI_CSE_19_JAN_25 },
    IIT_ROPAR_AI_NONTECH_19_JAN_25: { ...baseData.IIT_ROPAR_AI_NONTECH_19_JAN_25, ...modifiedData.IIT_ROPAR_AI_NONTECH_19_JAN_25 },
    // ! ======== 26th Jan code ========
    IIT_MANDI_CYBERSECURITY_26_JAN_25: { ...baseData.IIT_MANDI_CYBERSECURITY_26_JAN_25, ...modifiedData.IIT_MANDI_CYBERSECURITY_26_JAN_25 },
    IIT_GUWAHATI_DEV_OPS_26_JAN_25: { ...baseData.IIT_GUWAHATI_DEV_OPS_26_JAN_25, ...modifiedData.IIT_GUWAHATI_DEV_OPS_26_JAN_25 },
    IIT_MANDI_BA_26_JAN_25: { ...baseData.IIT_MANDI_BA_26_JAN_25, ...modifiedData.IIT_MANDI_BA_26_JAN_25 },
    IIT_GUWAHATI_DS_ML_26_JAN_25: { ...baseData.IIT_GUWAHATI_DS_ML_26_JAN_25, ...modifiedData.IIT_GUWAHATI_DS_ML_26_JAN_25 },
 // ! ======== 2th Feb code ========
    IIT_MANDI_CYBERSECURITY_2_FEB_25: { ...baseData.IIT_MANDI_CYBERSECURITY_2_FEB_25, ...modifiedData.IIT_MANDI_CYBERSECURITY_2_FEB_25 },
    IIT_GUWAHATI_DEV_OPS_2_FEB_25: { ...baseData.IIT_GUWAHATI_DEV_OPS_2_FEB_25, ...modifiedData.IIT_GUWAHATI_DEV_OPS_2_FEB_25 },
    IIT_MANDI_BA_2_FEB_25: { ...baseData.IIT_MANDI_BA_2_FEB_25, ...modifiedData.IIT_MANDI_BA_2_FEB_25 },

    // ! ======== 11th Feb code ========
    IIT_GUWAHATI_DEVOPS_16_FEB_25: { ...baseData.IIT_GUWAHATI_DEVOPS_16_FEB_25, ...modifiedData.IIT_GUWAHATI_DEVOPS_16_FEB_25 },
    
  };
  
};

export const kitPrice = {
  IIT_MANDI_ES: 30000,
  IIT_MANDI_ES_8_DEC_24: 20000,
};
